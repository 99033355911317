import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const FacebookIcon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 24 24" fill="black" {...props}>
            <path d="M14 14H16.5L17.5 10H14V8C14 6.97062 14 6 16 6H17.5V2.6401C17.1743 2.59685 15.943 2.5 14.6429 2.5C11.9284 2.5 10 4.15686 10 7.19971V10H7V14H10V22.5H14V14Z" />
        </Icon>
    );
};
