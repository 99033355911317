import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { fontFamily } from '../font';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    accordionAnatomy.keys
);

const baseStyle = definePartsStyle({
    button: {
        fontFamily: fontFamily
    }
});

export const Accordion = defineMultiStyleConfig({
    baseStyle
});
