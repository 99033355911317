import axios from 'axios';

import { SortDirection, SortDirectionType } from '@/constants/sort-direction';
import { EventContent, StrapiDataObject, StrapiResponse } from '@/models/cms';
import { LocaleProps } from '@/models/props/LocalizationProps';
import { EnvironmentService } from '@/services/EnvironmentService';

const EVENTS_ENDPOINT = EnvironmentService.getCmsInternalApiUrl().concat('/events');

type FilterOptions = {
    locale: LocaleProps;
    pageSize?: number;
    page?: number;
    sort?: 'orderNumber' | 'id' | 'slug' | 'title' | 'createdAt' | 'updatedAt';
    order?: SortDirectionType;
};

export class EventsService {
    static getEventBySlug(slug: string, locale: LocaleProps) {
        const url = new URL(EVENTS_ENDPOINT);
        const urlSearchParams = url.searchParams;

        urlSearchParams.set('filters[slug][$eq]', slug);
        urlSearchParams.set('locale', locale);
        urlSearchParams.append('populate', 'image');

        return axios
            .get<StrapiResponse<EventContent[]>>(decodeURIComponent(url.toString()))
            .then((response) => response.data)
            .then<StrapiDataObject<EventContent>>((data) => data.data[0]);
    }

    static getEvents(opts: FilterOptions) {
        const { locale, sort, order, page, pageSize } = opts;
        const url = new URL(EVENTS_ENDPOINT);
        const urlSearchParams = url.searchParams;

        urlSearchParams.set('locale', locale);
        urlSearchParams.append('populate', 'image');

        if (page) {
            urlSearchParams.set('pagination[page]', page.toString());
        }

        if (pageSize) {
            urlSearchParams.set('pagination[pageSize]', pageSize.toString());
        }

        if (sort) {
            urlSearchParams.set(
                'sort[0]',
                sort + encodeURIComponent(':') + (order || SortDirection.Descending)
            );
        }

        return axios
            .get<StrapiResponse<EventContent[]>>(decodeURIComponent(url.toString()))
            .then((response) => response.data);
    }
}
