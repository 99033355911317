import { ComponentStyleConfig } from '@chakra-ui/react';

const variantUnstyled = {
    field: {
        bg: 'white',
        borderRadius: 0,
        borderColor: 'grey.500',
        _hover: {
            borderColor: 'grey.500'
        },
        _placeholder: {
            color: 'grey.500'
        }
    },
    addon: {
        borderRadius: 0,
        borderColor: 'grey.500'
    },
    errorBorderColor: 'danger.400'
};

const variantOutline = {
    field: {
        bg: 'white',
        borderRadius: 0,
        borderColor: 'grey.500',
        _hover: {
            borderColor: 'brand.main'
        },
        _placeholder: {
            color: 'grey.500'
        }
    },
    addon: {
        borderRadius: 0,
        bgColor: 'accent.500',
        borderColor: 'grey.500'
    },
    errorBorderColor: 'danger.400'
};

const variantUnderline = {
    addon: {
        baseStyle: {
            borderRadius: 0
        }
    },
    field: {
        bg: 'white',
        borderRadius: 0,
        borderBottom: '1px solid black',
        height: 9,
        pb: 2,
        _dark: {
            color: 'whiteAlpha'
        },
        _focusVisible: {
            borderColor: 'brand.main',
            _dark: {
                bg: 'whiteAlpha.100'
            }
        },
        _placeholder: {
            color: 'grey.500'
        }
    }
};

export const Input: ComponentStyleConfig = {
    defaultProps: {
        focusBorderColor: 'brand.darker',
        errorBorderColor: 'danger.500',
        borderRadius: 0
    },
    variants: {
        unstyled: variantUnstyled,
        outline: variantOutline,
        underline: variantUnderline
    }
};
