import { ComponentStyleConfig } from '@chakra-ui/react';

export const Button: ComponentStyleConfig = {
    baseStyle: {
        borderRadius: 0,
        fontWeight: 500
    },
    variants: {
        underlineBrand: {
            color: 'inherit',
            fontWeight: 'regular',
            width: 'max-content',
            textUnderlineOffset: '5px',
            textDecoration: 'underline',
            textDecorationThickness: '1px',
            textDecorationColor: 'brand.darker',
            px: 0,
            _hover: {
                fontWeight: 'medium',
                textDecorationThickness: '1px',
                textDecorationColor: 'grey.main',
                letterSpacing: '-0.14px'
            }
        },
        solid: {
            bgColor: 'brand.darker',
            _hover: {
                bgColor: 'brand.darkest'
            }
        },
        ghost: {
            _hover: {
                bgColor: 'accent.200'
            }
        },
        outline: {
            borderRadius: 0,
            borderColor: 'brand.darker',
            bgColor: 'white',
            color: 'black',
            _hover: {
                borderColor: 'grey.main',
                bgColor: 'white'
            }
        }
    },
    defaultProps: {
        colorScheme: 'brand'
    }
};
