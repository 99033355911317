import { ComponentStyleConfig } from '@chakra-ui/react';

export const Textarea: ComponentStyleConfig = {
    baseStyle: {
        bg: 'white',
        border: '1px solid',
        borderRadius: 0,
        borderColor: 'grey.500',
        _hover: {
            borderColor: 'brand.main'
        },
        _placeholder: {
            color: 'grey.500'
        }
    },
    variants: {
        outline: {
            bg: 'white',
            borderColor: 'grey.500',
            _hover: {
                borderColor: 'brand.main'
            },
            _placeholder: {
                color: 'grey.500'
            }
        }
    },
    defaultProps: {
        focusBorderColor: 'brand.main',
        errorBorderColor: 'danger.500'
    }
};
