import { Flex, Text } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormattedPrice } from '@/components/FormattedPrice/FormattedPrice';

import { AppService } from '@/services';

type MyMoneyProps = {
    currencySymbol?: string | null;
    label: string;
    money?: number | null;
};

export const MoneyBox = (props: PropsWithChildren<MyMoneyProps>) => {
    const { money, label } = props;
    const currency = AppService.getInstanceCurrency();

    return (
        <Flex flexWrap="wrap" columnGap={2} justifyContent={{ sm: 'center' }} textAlign="center">
            <Flex columnGap={2} alignItems="center">
                {props.children}
                <Text color="black" whiteSpace="nowrap">
                    {label}
                </Text>
            </Flex>
            <Flex columnGap={2}>
                <Text color="black">
                    <FormattedPrice value={money || 0} currency={currency} />
                </Text>
                <Text>
                    <FormattedMessage id="gross" />
                </Text>
            </Flex>
        </Flex>
    );
};
