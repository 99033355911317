import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const CarIcon = (props: IconProps) => {
    return (
        <Icon
            viewBox="0 0 32 32"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
            {...props}
        >
            <path d="M6.66406 22.6667C6.66406 23.3739 6.94501 24.0522 7.44511 24.5523C7.94521 25.0524 8.62349 25.3333 9.33073 25.3333C10.038 25.3333 10.7162 25.0524 11.2163 24.5523C11.7164 24.0522 11.9974 23.3739 11.9974 22.6667C11.9974 21.9594 11.7164 21.2811 11.2163 20.781C10.7162 20.281 10.038 20 9.33073 20C8.62349 20 7.94521 20.281 7.44511 20.781C6.94501 21.2811 6.66406 21.9594 6.66406 22.6667Z" />
            <path d="M20 22.6667C20 23.3739 20.281 24.0522 20.781 24.5523C21.2811 25.0524 21.9594 25.3333 22.6667 25.3333C23.3739 25.3333 24.0522 25.0524 24.5523 24.5523C25.0524 24.0522 25.3333 23.3739 25.3333 22.6667C25.3333 21.9594 25.0524 21.2811 24.5523 20.781C24.0522 20.281 23.3739 20 22.6667 20C21.9594 20 21.2811 20.281 20.781 20.781C20.281 21.2811 20 21.9594 20 22.6667Z" />
            <path d="M6.66406 22.667H3.9974V17.3337M2.66406 6.66699H17.3307V22.667M11.9974 22.667H19.9974M25.3307 22.667H27.9974V14.667M27.9974 14.667H17.3307M27.9974 14.667L23.9974 8.00033H17.3307" />
            <path d="M4 12H9.33333" />
        </Icon>
    );
};
