import { Flex, VisuallyHidden } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { FacebookIcon, InstagramIcon, LinkIcon, YouTubeIcon } from '@/components/Icons';
import NavLink from '@/components/UI/Links/NavLink';

import { FooterProps } from '@/models/props/NavigationFooterProps';

const SocialIconsDictionary: Record<string, ReactNode> = {
    facebook: <FacebookIcon w={6} h={6} />,
    instagram: <InstagramIcon w={6} h={6} />,
    youtube: <YouTubeIcon w={6} h={6} />
} as const;

export const SocialMedia = (props: Pick<FooterProps, 'socialLinks'>) => {
    const { socialLinks } = props;

    const socialMediaLinkNodes = socialLinks
        ?.filter((item) => item.url)
        ?.map((item, index) => (
            <NavLink
                href={item.url as string}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
            >
                {item.label && <VisuallyHidden>{item.label}</VisuallyHidden>}
                {SocialIconsDictionary[item.id] || <LinkIcon w={6} h={6} />}
            </NavLink>
        ));

    return (
        <>
            {!!socialMediaLinkNodes?.length && (
                <Flex columnGap={{ base: 8, sm: 5 }}>{socialMediaLinkNodes}</Flex>
            )}
        </>
    );
};
