import {
    Box,
    Button,
    Container,
    Drawer,
    DrawerCloseButton,
    DrawerContent,
    DrawerOverlay,
    Flex,
    useDisclosure,
    useMediaQuery,
    VisuallyHidden
} from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Classes from './header.module.scss';

import ButtonCart from '@/components/Header/ButtonCart';
import ButtonCartUnlogged from '@/components/Header/ButtonCartUnlogged';
import { HamburgerIcon, HeartIcon, MagnifierIcon, UserIcon } from '@/components/Icons';
import LanguageSelector from '@/components/Language/LanguageSelector';
import MobileMenu from '@/components/MobileMenu';
import { UnreadNotificationsCounter } from '@/components/Notifications/UnreadNotificationsCounter';
import { ProductSearch } from '@/components/Search/ProductSearch';
import ShoppingCart from '@/components/ShoppingCart';
import ShoppingCartUnlogged from '@/components/ShoppingCartUnlogged';
import { BrandLink } from '@/components/UI/Links/BrandLink';
import { BrandMobileLink } from '@/components/UI/Links/BrandMobileLink';
import NavLink from '@/components/UI/Links/NavLink';
import { UserPanel } from '@/components/UserPanel/UserPanel';

import breakpoints from '@/../themes/default-theme/breakpoints';
import RoutePath from '@/constants/route-path';
import { ShoppingNavigationBar } from '@/layouts/ShoppingNavigationBar/ShoppingNavigationBar';
import { PersonalData } from '@/models/api/ProfileInfo';
import { HeaderProps } from '@/models/props/HeaderProps';

import { useApp } from '../../../context/appContext';
import { useCurrentUser } from '../../../context/currentUserContext';

export type DesktopProps = boolean | undefined;

export type PlacementProps = 'top' | 'bottom' | 'left' | 'right';

interface ScrollProps extends Omit<HeaderProps, 'scrolledLg' | 'scrollDirection'> {
    desktop: DesktopProps;
}

function HeaderTop(props: ScrollProps) {
    const { desktop } = props;
    const { showDisabledOrderingPopup, orderingAllowed } = useApp();
    const { data: session } = useSession();
    const user = session?.user as Pick<PersonalData, 'name' | 'surname'>;
    const { unreadNotificationsCounter } = useCurrentUser();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { basketLocked } = useShoppingCart();
    const { isOpen: isSearchOpen, onOpen: onSearchOpen, onClose: onSearchClose } = useDisclosure();
    const [dir, setDir]: [PlacementProps, Dispatch<SetStateAction<PlacementProps>>] =
        useState<PlacementProps>('right');
    const [buttonId, setButtonId] = useState<string>('');
    const intl = useIntl();
    const router = useRouter();

    const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.xl})`, {
        ssr: true,
        fallback: true
    });

    const obj = useMemo(
        () => ({
            onClose,
            router,
            intl
        }),
        [onClose, router, intl]
    );

    const openDrawerHandler = (
        ev: React.MouseEvent<HTMLButtonElement>,
        placement: PlacementProps
    ) => {
        const target: HTMLButtonElement = ev.currentTarget as HTMLButtonElement;

        if (target) {
            setButtonId(target.id);
        }

        if (
            !orderingAllowed &&
            ['shopping-cart-unlogged', 'button-cart'].indexOf(target.id) !== -1
        ) {
            showDisabledOrderingPopup();
            return;
        }

        setDir(placement);
        onOpen();
    };

    const toggleProductSearch = useCallback(() => {
        onSearchOpen();
    }, [onSearchOpen]);

    const closeDrawerHandler = useCallback(
        (value: boolean) => {
            if (value) onClose();
        },
        [onClose]
    );

    useEffect(() => {
        const handleRouteChange = () => {
            obj.onClose();
            onSearchClose();
        };

        obj.router.events.on('routeChangeStart', handleRouteChange);

        return () => {
            obj.router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [obj, onSearchClose]);

    useEffect(() => {
        if (isDesktop) {
            onSearchClose();
        }
    }, [isDesktop, onClose, onSearchClose]);

    return (
        <Box pos="relative" zIndex={10} pointerEvents="auto" bgColor="white">
            <Container maxW="container.3xl">
                <Flex
                    alignItems="stretch"
                    py={4}
                    transition="padding 300ms ease-out"
                    justifyContent={desktop ? 'space-between' : 'flex-start'}
                    minH={10}
                >
                    <Flex alignItems="center">
                        {!desktop && (
                            <>
                                <Button
                                    bgColor="transparent"
                                    variant="ghost"
                                    p={2}
                                    w="auto"
                                    h="auto"
                                    minW={0}
                                    mr={1}
                                    id="button-hamburger"
                                    onClick={(ev) => openDrawerHandler(ev, 'left')}
                                >
                                    <HamburgerIcon pointerEvents="none" fontSize={32} />
                                </Button>
                                <BrandMobileLink w="120px" h="auto" />
                            </>
                        )}

                        {desktop && (
                            <>
                                <BrandLink minW={{ base: '6.25rem', '3xl': '8.8125rem' }} />
                                <Box borderLeftWidth={1} borderLeftColor="grey.500" px={2}>
                                    <LanguageSelector />
                                </Box>
                            </>
                        )}
                    </Flex>

                    <Flex flexGrow={1} alignItems="center" justifyContent="flex-end">
                        {desktop && (
                            <Box textAlign="center" className={Classes.navigation}>
                                <ShoppingNavigationBar />
                            </Box>
                        )}

                        <Flex alignItems="stretch" columnGap={{ base: 2, '2xl': 3, '3xl': 6 }}>
                            <Button
                                variant="link"
                                p={2}
                                _hover={{ bgColor: 'accent.200' }}
                                onClick={toggleProductSearch}
                            >
                                <VisuallyHidden>
                                    <FormattedMessage
                                        id={
                                            isSearchOpen
                                                ? 'search.close-search-panel'
                                                : 'search.open-search-panel'
                                        }
                                    />
                                </VisuallyHidden>
                                <MagnifierIcon
                                    w={{ base: 8, xl: 7, '3xl': 8 }}
                                    h={{ base: 8, xl: 7, '3xl': 8 }}
                                />
                            </Button>
                            {session && (
                                <Button
                                    variant="link"
                                    display="flex"
                                    p={2}
                                    id="user-panel"
                                    _hover={{ bgColor: 'accent.200' }}
                                    onClick={(ev) => openDrawerHandler(ev, 'right')}
                                >
                                    <Box position="relative">
                                        <UserIcon
                                            w={{ base: 8, xl: 7, '3xl': 8 }}
                                            h={{ base: 8, xl: 7, '3xl': 8 }}
                                        />
                                        {!!unreadNotificationsCounter && (
                                            <UnreadNotificationsCounter
                                                fontSize="10px"
                                                fontWeight="medium"
                                                p={0}
                                                position="absolute"
                                                top={1}
                                                right={-1}
                                                borderRadius="50%"
                                            />
                                        )}
                                        <VisuallyHidden>
                                            {user?.name} {user?.surname}
                                        </VisuallyHidden>
                                    </Box>
                                </Button>
                            )}
                            {desktop && session?.user && (
                                <NavLink
                                    href={RoutePath.Favourites}
                                    display="block"
                                    p={2}
                                    _hover={{ bgColor: 'accent.200' }}
                                >
                                    <HeartIcon
                                        pointerEvents="none"
                                        w={{ base: 8, xl: 7, '3xl': 8 }}
                                        h={{ base: 8, xl: 7, '3xl': 8 }}
                                    />
                                    <VisuallyHidden>
                                        <FormattedMessage id="favorites" />
                                    </VisuallyHidden>
                                </NavLink>
                            )}

                            {session ? (
                                <ButtonCart
                                    id="button-cart"
                                    border="none"
                                    h="auto"
                                    onClick={(ev) =>
                                        !basketLocked && openDrawerHandler(ev, 'right')
                                    }
                                />
                            ) : (
                                <ButtonCartUnlogged
                                    id="shopping-cart-unlogged"
                                    h="auto"
                                    onClick={(ev) => openDrawerHandler(ev, 'right')}
                                />
                            )}
                        </Flex>
                    </Flex>
                </Flex>
            </Container>

            <Drawer
                isOpen={isOpen}
                placement={dir}
                onClose={onClose}
                size={{ base: 'full', md: buttonId === 'user-panel' ? 'md' : 'lg' }}
            >
                <DrawerOverlay
                    bgColor={{
                        base: 'white',
                        md: 'blackAlpha.600'
                    }}
                />
                <DrawerContent maxH="unset" h="100%" boxShadow="none">
                    {buttonId === 'button-cart' && <ShoppingCart onClose={closeDrawerHandler} />}
                    {buttonId === 'shopping-cart-unlogged' && (
                        <ShoppingCartUnlogged onClose={closeDrawerHandler} />
                    )}
                    {buttonId === 'user-panel' && <UserPanel onRouteChange={() => onClose()} />}
                    {buttonId === 'button-hamburger' && <MobileMenu />}
                </DrawerContent>
            </Drawer>
            <Drawer isOpen={isSearchOpen} onClose={onSearchClose} placement="top">
                <DrawerContent>
                    <DrawerCloseButton
                        top="50%"
                        transform="translateY(-50%)"
                        onClick={() => onSearchClose()}
                        aria-label={intl.formatMessage({ id: 'close' })}
                    />
                    <Box pl={{ base: 0, sm: 4 }} pr={12}>
                        <ProductSearch />
                    </Box>
                </DrawerContent>
            </Drawer>
        </Box>
    );
}

export default HeaderTop;
