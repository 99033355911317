import { ComponentStyleConfig } from '@chakra-ui/react';

export const Switch: ComponentStyleConfig = {
    baseStyle: {
        thumb: {
            alignSelf: 'center',
            bgColor: 'brand.main',
            w: 2,
            h: 2,
            transitionProperty: 'all',
            transform: 'translateX(4px)',
            _checked: {
                bgColor: 'white',
                transform: 'translateX(18px)'
            }
        },
        track: {
            p: 0,
            bgColor: 'transparent',
            borderWidth: 3,
            borderColor: 'brand.main',
            _checked: {
                bg: 'brand.main'
            }
        }
    },
    defaultProps: {
        colorScheme: 'brand'
    }
};
