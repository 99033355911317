import { Flex } from '@chakra-ui/react';
import { useCurrentUser } from 'context/currentUserContext';
import { useRouter } from 'next/router';
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import RegulationsConsentPopup from '@/components/RegulationsConsentPopup.tsx';

import RoutePath from '@/constants/route-path';
import { AgreementData } from '@/models/api/ProfileInfo';
import { LocaleProps } from '@/models/props/LocalizationProps';
import { AdditionalContentService } from '@/services';

type Props = {
    children: React.ReactNode;
};

type ServiceRegulationsProps = {
    accepted: boolean;
    acceptRegulations: (userAgreements: AgreementData | null) => void;
};

const ServiceRegulationsContext = createContext<ServiceRegulationsProps | null>(null);

export function useSessionRegulations() {
    const sessionRegulations = useContext(ServiceRegulationsContext);
    if (!sessionRegulations) {
        throw new Error('useSessionRegulations must be used within a ServiceRegulationsProvider');
    }
    return sessionRegulations;
}

export function ServiceRegulationsProvider({ children }: Props) {
    const { locale } = useIntl();
    const router = useRouter();
    const [showModal, setShowModal] = useState(false);
    const [modalClicked, setModalClicked] = useState(false);
    const [accepted, setAccepted] = useState(true);
    const containerRef = useRef<HTMLDivElement>(null);
    const [userAgreements, setUserAgreements] = useState<AgreementData | null>(null);
    const [regulationsConsentPopup, setRegulationsConsentPopup] = useState<React.ReactNode[]>([]);
    const { currentUser } = useCurrentUser();
    const { agreements, company } = currentUser || {};
    const [content, setContent] = useState('');

    const acceptRegulations = useCallback(async (userAgreements: AgreementData | null) => {
        setShowModal(false);
        setAccepted(true);
        setModalClicked(true);
        setUserAgreements(userAgreements);
    }, []);

    const handleUserActivity = useCallback(async () => {
        if (!accepted && showModal && !modalClicked) {
            setAccepted(false);
            setShowModal(true);
            // Display next modal
            setRegulationsConsentPopup((prevComponents) => [
                ...prevComponents,
                <RegulationsConsentPopup content={content} key={prevComponents.length} />
            ]);
        }
    }, [accepted, showModal, modalClicked, content]);

    const fetchRegulationChangesContent = useCallback(() => {
        return currentUser?.company
            ? AdditionalContentService.getCompanyUserRegulationChangesContent(locale as LocaleProps)
            : AdditionalContentService.getStandardUserRegulationChangesContent(
                  locale as LocaleProps
              );
    }, [currentUser?.company, locale]);

    const hasAgreementChanged = (acceptedDate: number, resetDate: number) => {
        return !!acceptedDate && !!resetDate && acceptedDate < resetDate;
    };

    const isPrivacyPolicyAcceptanceRequired = useCallback(
        (agreements: AgreementData) => !agreements.privacyPolicyAccepted,
        []
    );

    const isRodoAcceptanceRequired = useCallback(
        (agreements: AgreementData) => !agreements.rodoAccepted,
        []
    );

    const hasAnyAgreementChanged = useCallback(
        (agreements?: AgreementData) => {
            if (!agreements) {
                return false;
            }

            const {
                rodoMarketingAcceptedDate,
                sponsorAcceptedDate,
                rodoMarketingResetDate,
                sponsorResetDate
            } = agreements;
            const agreementsChanges = [
                isPrivacyPolicyAcceptanceRequired(agreements),
                isRodoAcceptanceRequired(agreements),
                hasAgreementChanged(rodoMarketingAcceptedDate, rodoMarketingResetDate),
                hasAgreementChanged(sponsorAcceptedDate, sponsorResetDate)
            ];
            return agreementsChanges.some((item) => item);
        },
        [isPrivacyPolicyAcceptanceRequired, isRodoAcceptanceRequired]
    );

    useEffect(() => {
        const container = containerRef.current;
        if (container && showModal) {
            container.addEventListener('mousedown', handleUserActivity);
            container.addEventListener('touchstart', handleUserActivity);
            container.addEventListener('scroll', handleUserActivity);

            return () => {
                container.removeEventListener('mousedown', handleUserActivity);
                container.removeEventListener('touchstart', handleUserActivity);
                container.removeEventListener('scroll', handleUserActivity);
            };
        }
    }, [showModal, handleUserActivity]);

    useEffect(() => {
        const agreementsChanged = hasAnyAgreementChanged(agreements);
        if (
            agreements &&
            agreementsChanged &&
            !modalClicked &&
            userAgreements === null &&
            router.route !== RoutePath.PrivacyPolicy &&
            router.route !== RoutePath.ClubRegulations
        ) {
            fetchRegulationChangesContent().then((content) => {
                setContent(content?.attributes?.content);
                setUserAgreements(agreements);
                setAccepted(false);
                setShowModal(true);
            });
        }
    }, [
        agreements,
        modalClicked,
        userAgreements,
        router,
        company,
        fetchRegulationChangesContent,
        hasAnyAgreementChanged
    ]);

    return (
        <ServiceRegulationsContext.Provider value={{ accepted, acceptRegulations }}>
            {regulationsConsentPopup.length === 0 && <RegulationsConsentPopup content={content} />}
            {regulationsConsentPopup}
            <Flex ref={containerRef} flexDirection="column" flex={1}>
                {children}
            </Flex>
        </ServiceRegulationsContext.Provider>
    );
}
