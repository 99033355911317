import axios from 'axios';

import { getAuthHeaders } from '@/lib/httpsAgent';

import { OptionsWithServerSideRequest } from '@/models/api';
import { ContactForm } from '@/models/api/Contact';
import { LocaleProps } from '@/models/props/LocalizationProps';
import { AppService } from '@/services/AppService';
import { EnvironmentService } from '@/services/EnvironmentService';
import { StrapiUtilsService } from '@/services/StrapiUtilsService';

const BASE_ENDPOINT = EnvironmentService.getPublicApiURL();
const CONTACT_FORM_HANDLER_URI = EnvironmentService.getPublicBaseURL() + '/contactForm';

async function getContact(locale: LocaleProps) {
    const url = new URL(EnvironmentService.getCmsInternalApiUrl().concat('/contact-pages'));

    const urlSearchParams = url.searchParams;

    urlSearchParams.set('locale', locale);
    urlSearchParams.set('filters[region][$containsi]', AppService.getInstanceRegion());
    urlSearchParams.set('filters[contentId][$eq]', StrapiUtilsService.getPrefixedName('contact'));
    urlSearchParams.set('populate', 'media');

    const { data } = await axios.get(url.toString()).catch((error) => {
        throw new Error(error || 'ContactService: Something went wrong!');
    });

    return data?.data?.[0].attributes;
}

async function sendContactForm(data: ContactForm, opts?: OptionsWithServerSideRequest) {
    const headers = getAuthHeaders(opts);
    const url = new URL(`${BASE_ENDPOINT}/email/sendEmailToBok`);
    const subject = data.subject;
    const content = data.content;
    const email = data.email;
    const attachments = data.attachments;

    return axios.post<ContactForm>(
        url.toString(),
        {
            subject,
            content,
            email,
            attachments
        },
        { headers }
    );
}

async function verifyRecaptcha(token: string) {
    return axios.post(CONTACT_FORM_HANDLER_URI, { token }).then((res) => res.data);
}

export const ContactService = {
    getContact,
    sendContactForm,
    verifyRecaptcha
};
