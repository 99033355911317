import { Alert as ChakraAlert, AlertProps, AlertStatus } from '@chakra-ui/alert';
import { createContext, PropsWithChildren, useContext } from 'react';

type AlertContextProps = {
    status: AlertStatus;
};

const AlertContext = createContext<AlertContextProps>({} as AlertContextProps);

export function useAlert() {
    const context = useContext(AlertContext);
    if (!context) {
        throw new Error('useAlert must be used with <Alert>');
    }
    return context;
}

export function Alert(props: PropsWithChildren<AlertProps>) {
    const { status = 'info', ...restProps } = props;
    return (
        <AlertContext.Provider
            value={{
                status
            }}
        >
            <ChakraAlert status={status} {...restProps} />
        </AlertContext.Provider>
    );
}
