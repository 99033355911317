import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const AlertIcon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 20 20" {...props}>
            <path
                d="M3 1.75C2.66848 1.75 2.35054 1.8817 2.11612 2.11612C1.8817 2.35054 1.75 2.66848 1.75 3V17C1.75 17.3315 1.8817 17.6495 2.11612 17.8839C2.35054 18.1183 2.66848 18.25 3 18.25H17C17.3315 18.25 17.6495 18.1183 17.8839 17.8839C18.1183 17.6495 18.25 17.3315 18.25 17V3C18.25 2.66848 18.1183 2.35054 17.8839 2.11612C17.6495 1.8817 17.3315 1.75 17 1.75H3ZM1.05546 1.05546C1.57118 0.539731 2.27065 0.25 3 0.25H17C17.7293 0.25 18.4288 0.539731 18.9445 1.05546C19.4603 1.57118 19.75 2.27065 19.75 3V17C19.75 17.7293 19.4603 18.4288 18.9445 18.9445C18.4288 19.4603 17.7293 19.75 17 19.75H3C2.27065 19.75 1.57118 19.4603 1.05546 18.9445C0.539731 18.4288 0.25 17.7293 0.25 17V3C0.25 2.27065 0.539731 1.57118 1.05546 1.05546ZM9.25 7C9.25 6.58579 9.58579 6.25 10 6.25H10.01C10.4242 6.25 10.76 6.58579 10.76 7C10.76 7.41421 10.4242 7.75 10.01 7.75H10C9.58579 7.75 9.25 7.41421 9.25 7ZM9 9.25C8.58579 9.25 8.25 9.58579 8.25 10C8.25 10.4142 8.58579 10.75 9 10.75H9.25V14C9.25 14.4142 9.58579 14.75 10 14.75H11C11.4142 14.75 11.75 14.4142 11.75 14C11.75 13.5858 11.4142 13.25 11 13.25H10.75V10C10.75 9.58579 10.4142 9.25 10 9.25H9Z"
                id="alert-icon"
            />
        </Icon>
    );
};
