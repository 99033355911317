import { BoxProps, Flex, FormLabel, Input, VisuallyHidden } from '@chakra-ui/react';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useToast } from '@/hooks/useToast';

import ProductQuantityButton from '@/components/ShoppingCart/ProductQuantityButton';

import { ButtonSize } from '@/models/props/ShoppingCartContextProps';

export type ProductQuantitySelectorProps = {
    disabled?: boolean;
    maxQuantity?: number;
    quantity: number | string;
    minQuantity?: number;
    size: ButtonSize;
    onDecrease?(value: number): void;
    onIncrease?(value: number): void;
    onInputChange?(value: number | string): void;
    onMaxQuantityExceeded?(quantity: number): void;
    onMinQuantityExceeded?(quantity: number): void;
    onQuantityUpdate?(quantity: number | string): void;
};

export const ProductQuantitySelector = (props: ProductQuantitySelectorProps & BoxProps) => {
    const {
        disabled,
        maxQuantity,
        quantity,
        size,
        onIncrease,
        onDecrease,
        onInputChange,
        onMaxQuantityExceeded,
        onMinQuantityExceeded,
        onQuantityUpdate,
        minQuantity,
        ...rest
    } = props;
    const { infoToast } = useToast();
    const intl = useIntl();
    const minQ = minQuantity ?? 1;

    const obj = useMemo(
        () => ({
            maxQuantity,
            quantity,
            size,
            onIncrease,
            onDecrease,
            onInputChange,
            onMaxQuantityExceeded,
            onMinQuantityExceeded,
            onQuantityUpdate,
            minQuantity,
            minQ
        }),
        [
            maxQuantity,
            quantity,
            size,
            onIncrease,
            onDecrease,
            onInputChange,
            onMaxQuantityExceeded,
            onMinQuantityExceeded,
            onQuantityUpdate,
            minQuantity,
            minQ
        ]
    );
    const [currQuantity, setCurrQuantity] = useState<number | string>(obj.quantity);
    const [detectedKeyDownHandler, setDetectedKeyDownHandler] = useState<boolean>(false);
    const [updateInput, setUpdateInput] = useState<boolean>(false);
    const timeOutRef = useRef<NodeJS.Timeout>();

    const detectKeyDownHandler = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            const value = (event.target as HTMLInputElement).value;

            if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                setDetectedKeyDownHandler(true);
                setCurrQuantity(value);

                clearTimeout(timeOutRef.current);

                timeOutRef.current = setTimeout(() => {
                    setUpdateInput(true);
                }, 1500);
            }

            if (event.key === 'Enter' || event.key === 'Tab') {
                onInputChange?.(value);
            }
        },
        [onInputChange]
    );

    const increaseQuantityHandle = () => {
        if (maxQuantity === undefined) {
            return;
        }
        if (currQuantity !== '' && +currQuantity < maxQuantity) {
            const newValue = +currQuantity + 1;
            onIncrease?.(newValue);
            onQuantityUpdate?.(newValue);
            onInputChange?.(newValue);
            setCurrQuantity(() => newValue);
        }
    };

    const decreaseQuantityHandle = () => {
        if (currQuantity !== '' && +currQuantity > minQ) {
            const newValue = +currQuantity - 1;
            onDecrease?.(newValue);
            onQuantityUpdate?.(newValue);
            onInputChange?.(newValue);
            setCurrQuantity(() => newValue);
        }
    };

    const changeInputQuantityHandler = useCallback(
        (event: ChangeEvent<HTMLInputElement>, keydown: boolean) => {
            const newValue = event.target.value;
            const handleEmptyValue = () => {
                onInputChange?.('');
                setCurrQuantity('');
            };

            if (newValue === '') {
                handleEmptyValue();
                return;
            }

            let inputValue = Number(newValue);

            if (!isNaN(inputValue)) {
                if (minQuantity !== undefined && inputValue < minQuantity) {
                    inputValue = minQuantity;
                } else if (maxQuantity !== undefined && inputValue > maxQuantity) {
                    inputValue = maxQuantity;
                    infoToast({
                        description: intl.formatMessage(
                            {
                                id: 'basket.reached-max-order'
                            },
                            { maxItems: maxQuantity.toString() }
                        )
                    });
                }

                if (!keydown) onInputChange?.(inputValue);

                setCurrQuantity(inputValue);
            } else {
                handleEmptyValue();
            }
        },
        [maxQuantity, minQuantity, onInputChange, intl, infoToast]
    );

    const updateQuantityHandler = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            let newQuantity = event.target.valueAsNumber;

            if (newQuantity < obj.minQ) {
                newQuantity = obj.minQ;
                obj.onMinQuantityExceeded?.(obj.minQ);
            }

            if (obj.maxQuantity !== undefined && newQuantity > obj.maxQuantity) {
                newQuantity = obj.maxQuantity;
                obj.onMaxQuantityExceeded?.(obj.maxQuantity);
            }

            if (isNaN(newQuantity)) {
                newQuantity = 0;
                onInputChange?.(0);
            }

            setCurrQuantity(() => newQuantity);
            onQuantityUpdate?.(newQuantity);

            setDetectedKeyDownHandler(false);
        },
        [onInputChange, onQuantityUpdate, obj]
    );

    useEffect(() => {
        setCurrQuantity(obj.quantity);
    }, [obj.quantity]);

    useEffect(() => {
        if (updateInput) {
            onInputChange?.(currQuantity);
            setUpdateInput(false);
        }
    }, [updateInput, onInputChange, currQuantity]);

    return (
        <Flex
            alignItems="center"
            textAlign="center"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="grey.main"
            overflow="hidden"
            p={size === 'regular' ? '6px' : '3px'}
            w="100%"
            h={size === 'regular' ? '46px' : '32px'}
            maxW={size === 'small' ? 20 : 'none'}
            mr={1}
            columnGap={size === 'regular' ? 2 : 0}
            {...rest}
        >
            <ProductQuantityButton
                onClick={() => decreaseQuantityHandle()}
                variant="down"
                size={size}
                disabled={+currQuantity <= minQ}
            />
            <FormLabel
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
                height={8}
                m={0}
                color="black"
            >
                <VisuallyHidden>
                    <FormattedMessage id="products-quantity" />
                </VisuallyHidden>
                <Input
                    onChange={(event) => {
                        changeInputQuantityHandler(event, detectedKeyDownHandler);
                    }}
                    onBlur={updateQuantityHandler}
                    onKeyDown={detectKeyDownHandler}
                    value={currQuantity ?? ''}
                    variant="unstyled"
                    height={8}
                    textAlign="center"
                    type="number"
                    min={0}
                    fontWeight="semibold"
                    fontSize="md"
                    max={maxQuantity}
                    readOnly={disabled}
                />
            </FormLabel>
            <ProductQuantityButton
                onClick={() => increaseQuantityHandle()}
                variant="up"
                size={size}
                disabled={maxQuantity !== undefined && +currQuantity >= maxQuantity}
            />
        </Flex>
    );
};
