import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const RightIcon = (props: IconProps) => {
    return (
        <Icon width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.25 12C4.25 11.5858 4.58579 11.25 5 11.25L19 11.25C19.4142 11.25 19.75 11.5858 19.75 12C19.75 12.4142 19.4142 12.75 19 12.75L5 12.75C4.58579 12.75 4.25 12.4142 4.25 12Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.4697 7.46967C14.7626 7.17678 15.2374 7.17678 15.5303 7.46967L19.5303 11.4697C19.8232 11.7626 19.8232 12.2374 19.5303 12.5303C19.2374 12.8232 18.7626 12.8232 18.4697 12.5303L14.4697 8.53033C14.1768 8.23744 14.1768 7.76256 14.4697 7.46967Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.4697 16.5303C14.1768 16.2374 14.1768 15.7626 14.4697 15.4697L18.4697 11.4697C18.7626 11.1768 19.2374 11.1768 19.5303 11.4697C19.8232 11.7626 19.8232 12.2374 19.5303 12.5303L15.5303 16.5303C15.2374 16.8232 14.7626 16.8232 14.4697 16.5303Z"
            />
        </Icon>
    );
};
