import { Icon } from '@chakra-ui/icon';
import { IconProps } from '@chakra-ui/icons';

export const YouTubeIcon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 24 24" fill="black" {...props}>
            <path d="M12.2439 4.5C12.778 4.50294 14.1143 4.51586 15.5341 4.57273L16.0375 4.59468C17.467 4.66236 18.8953 4.77798 19.6037 4.9755C20.5486 5.24095 21.2913 6.0155 21.5423 6.99732C21.942 8.55641 21.992 11.5994 21.9982 12.3358L21.9991 12.4884V12.4991C21.9991 12.4991 21.9991 12.5028 21.9991 12.5099L21.9982 12.6625C21.992 13.3989 21.942 16.4419 21.5423 18.001C21.2878 18.9864 20.5451 19.761 19.6037 20.0228C18.8953 20.2203 17.467 20.3359 16.0375 20.4036L15.5341 20.4255C14.1143 20.4824 12.778 20.4953 12.2439 20.4983L12.0095 20.4991H11.9991C11.9991 20.4991 11.9956 20.4991 11.9887 20.4991L11.7545 20.4983C10.6241 20.4921 5.89772 20.441 4.39451 20.0228C3.4496 19.7573 2.70692 18.9828 2.45587 18.001C2.0562 16.4419 2.00624 13.3989 2 12.6625V12.3358C2.00624 11.5994 2.0562 8.55641 2.45587 6.99732C2.7104 6.01186 3.45308 5.23732 4.39451 4.9755C5.89772 4.55723 10.6241 4.50622 11.7545 4.5H12.2439ZM9.99911 8.99914V15.9991L15.9991 12.4991L9.99911 8.99914Z" />
        </Icon>
    );
};
