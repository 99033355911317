import { Icon } from '@chakra-ui/icon';
import { IconProps } from '@chakra-ui/icons';

export const HamburgerIcon = (props: IconProps) => {
    return (
        <Icon
            viewBox="0 0 32 32"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...props}
        >
            <path d="M26.6641 8H5.33073" />
            <path d="M22.6641 16H5.33073" />
            <path d="M18.6641 24H5.33073" />
        </Icon>
    );
};
