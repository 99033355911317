import { NumberFormatOptions } from '@formatjs/ecma402-abstract';
import { useMemo } from 'react';
import { FormattedNumber } from 'react-intl';

import { AppService } from '@/services';

type FormattedPriceProps = {
    value: string | number | undefined | null;
    currency: string | undefined;
} & NumberFormatOptions;

export const FormattedPrice = (props: FormattedPriceProps) => {
    const { value, currency, ...restProps } = props;

    const price = useMemo(() => {
        let priceValue = value;
        if (typeof priceValue === 'string') {
            priceValue = +priceValue.replace(',', '.');
        }

        if (!priceValue || isNaN(priceValue)) {
            return 0;
        }

        return priceValue;
    }, [value]);

    return (
        <FormattedNumber
            value={price}
            currency={currency || AppService.getInstanceCurrency()}
            style="currency"
            currencyDisplay="symbol"
            {...restProps}
        />
    );
};
