import { ChevronRightIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    DrawerBody,
    DrawerCloseButton,
    DrawerFooter,
    DrawerHeader,
    Flex,
    ListItem,
    UnorderedList,
    useDisclosure
} from '@chakra-ui/react';
import { useSession } from 'next-auth/react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import classes from './mobileMenu.module.scss';

import { loggedUserShoppingMenu, unLoggedUserShoppingMenu } from '@/data/menu';

import LanguageSelector from '@/components/Language/LanguageSelector';
import { SubmenuDrawer } from '@/components/MobileMenu/SubmenuDrawer';
import { BrandMobileLink } from '@/components/UI/Links/BrandMobileLink';
import NavLink from '@/components/UI/Links/NavLink';

import { MenuLink } from '@/models/props/CurrentOfferProps';

function MobileMenu() {
    const { data } = useSession();
    const shoppingMenu = data?.user ? loggedUserShoppingMenu : unLoggedUserShoppingMenu;
    const {
        isOpen: isSubmenuDrowerOpen,
        onClose: onSubmenuDrowerClose,
        onOpen: onSubmenuDrowerOpen
    } = useDisclosure();

    const [item, setItem] = useState<MenuLink>();

    const shoppingMenuListElements = shoppingMenu.map((item, index) => {
        const { href, translationKey, submenu, skipRouter } = item as MenuLink;
        return submenu ? (
            <ListItem key={index} borderBottomWidth={0}>
                <Button
                    display="flex"
                    justifyContent="space-between"
                    variant="link"
                    w="100%"
                    h="auto"
                    textAlign="left"
                    color="inherit"
                    onClick={() => {
                        setItem(item);
                        onSubmenuDrowerOpen();
                    }}
                    borderColor="grey.400"
                    py={6}
                >
                    <FormattedMessage id={translationKey} />
                    <ChevronRightIcon />
                </Button>
            </ListItem>
        ) : (
            <ListItem key={index}>
                <NavLink href={href} skipRouter={skipRouter} py={6}>
                    <FormattedMessage id={translationKey} />
                </NavLink>
            </ListItem>
        );
    });

    return (
        <>
            <DrawerHeader pt={6}>
                <Flex alignItems="center" justifyContent="flex-start" columnGap={5}>
                    <BrandMobileLink h="auto" w="120px" position="relative" />
                    <LanguageSelector />
                    <DrawerCloseButton ml="auto" position="static" fontSize="12px" />
                </Flex>
            </DrawerHeader>

            <DrawerBody px={0}>
                <Box px={7}>
                    <Box as="nav">
                        <UnorderedList marginLeft={0} className={classes.listMenu}>
                            {shoppingMenuListElements}
                        </UnorderedList>
                    </Box>
                </Box>
            </DrawerBody>

            <DrawerFooter display="block"></DrawerFooter>

            {item && (
                <SubmenuDrawer
                    item={item}
                    open={isSubmenuDrowerOpen}
                    drawerClose={onSubmenuDrowerClose}
                />
            )}
        </>
    );
}

export default MobileMenu;
