import { Box, Button, useMediaQuery, VisuallyHidden } from '@chakra-ui/react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ArrowDownIcon } from '@/components/Icons';

import breakpoints from '../../../themes/default-theme/breakpoints';

export function GoTop() {
    const [isVisible, setIsVisible] = useState(false);
    const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.xl})`, {
        ssr: true,
        fallback: true
    });

    const [desktop, setDesktop]: [
        boolean | undefined,
        Dispatch<SetStateAction<boolean | undefined>>
    ] = useState();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        setDesktop(isDesktop);
    }, [isDesktop]);

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.scrollY > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    return (
        <>
            {desktop && isVisible && (
                <Box onClick={scrollToTop} position="fixed" bottom="300px" right={20} zIndex={5}>
                    <Button
                        variant="solid"
                        w={20}
                        h={20}
                        borderRadius="100%"
                        backgroundColor="accent.main"
                        boxShadow="0 10px 15px 0 #00000020"
                        _hover={{ backgroundColor: 'accent.600' }}
                    >
                        <ArrowDownIcon
                            stroke="grey.main"
                            fontSize="2rem"
                            transform="rotate(180deg)"
                        />
                        <VisuallyHidden>
                            <FormattedMessage id="scroll-to-top" />
                        </VisuallyHidden>
                    </Button>
                </Box>
            )}
        </>
    );
}
