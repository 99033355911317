import { forwardRef, Heading, HeadingProps } from '@chakra-ui/react';

export const H1 = forwardRef<HeadingProps, 'h1'>((props, ref) => {
    return (
        <Heading
            as="h1"
            fontWeight="normal"
            fontSize={{ base: '4xl', lg: 'h1' }}
            lineHeight={{ base: 1, lg: 1.33 }}
            ref={ref}
            {...props}
        />
    );
});
