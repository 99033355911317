import { ComponentStyleConfig } from '@chakra-ui/react';

export const Form: ComponentStyleConfig = {
    baseStyle: {
        container: {
            marginBottom: 2.5
        },
        requiredIndicator: {
            color: 'danger.500'
        }
    }
};

export default Form;
