import { Box, Flex, ListItem, Spinner, Text, UnorderedList } from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { NextImage } from '@/components/Image';

import { Product } from '@/models/api';
import { ProductSearchResultsListProps } from '@/models/props/ProductSearchResultsListProps';
import { StrapiUtilsService } from '@/services/StrapiUtilsService';
import { ProductUtil } from '@/utils';

export const ProductSearchResultsList = (props: ProductSearchResultsListProps) => {
    const router = useRouter();
    const { setSearchProduct } = useShoppingCart();
    const { products, isLoading, destination, selectProductCallback } = props;

    const obj = useMemo(
        () => ({
            destination,
            setSearchProduct,
            selectProductCallback
        }),
        [destination, setSearchProduct, selectProductCallback]
    );

    const listItemClickHandler = useCallback(
        async (product: Product) => {
            if (obj.destination === 'checkout') {
                obj.setSearchProduct(product);
                obj.selectProductCallback(product);

                return;
            }

            const productUrl = ProductUtil.getProductUrl(product);

            await router.push({
                pathname: `/${productUrl}`
            });
        },
        [router, obj]
    );

    const displayImage = useCallback((item: Product) => {
        const imgObject =
            item?.mainImage?.attributes?.formats.small ||
            item?.mainImage?.attributes?.formats.thumbnail;

        if (!item.mainImage || !imgObject) {
            return;
        }

        return {
            src: StrapiUtilsService.updateUploadsUrl(imgObject.url),
            width: imgObject.width,
            height: imgObject.height,
            displayLogo: false
        };
    }, []);

    const resultItemElements = useMemo(() => {
        return products.map((product, resultIndex) => {
            const image = displayImage(product);

            return (
                <ListItem
                    key={resultIndex}
                    py={3}
                    px={4}
                    display="flex"
                    cursor="pointer"
                    lineHeight="shorter"
                    fontSize="sm"
                    position="relative"
                    onClick={() => listItemClickHandler(product)}
                    pointerEvents={product.productAvailableForSale ? 'auto' : 'none'}
                    _hover={{
                        bgColor: 'grey.200'
                    }}
                    _before={{
                        content: '""',
                        color: 'danger.500',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}
                >
                    <Flex
                        alignItems="start"
                        columnGap={4}
                        opacity={product.productAvailableForSale ? 1 : 0.2}
                    >
                        <Flex
                            alignItems="center"
                            justifyContent="center"
                            w={45}
                            h={35}
                            mt={1}
                            px={1}
                            bg="white"
                            flexShrink={0}
                            overflow="hidden"
                            borderColor="grey.border"
                            borderWidth={!image ? 1 : 0}
                        >
                            {image && (
                                <Box>
                                    <NextImage
                                        src={image.src}
                                        alt={product.title}
                                        width={image.width}
                                        height={image.height}
                                        opacity={1}
                                        style={{ objectFit: 'cover' }}
                                    />
                                </Box>
                            )}
                        </Flex>
                        <Text>{product.title}</Text>
                    </Flex>
                    {!product.productAvailableForSale && (
                        <Text
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            color="danger.500"
                            fontSize="xs"
                            left="50%"
                            top="50%"
                            transform="translate(-50%, -50%)"
                            position="absolute"
                            pointerEvents="none"
                            borderWidth="1px"
                            borderColor="grey.border"
                            width="100%"
                            height="calc(100% - 6px)"
                        >
                            {product.notAvailableMessage || (
                                <FormattedMessage id="product.unavailable" />
                            )}
                        </Text>
                    )}
                </ListItem>
            );
        });
    }, [displayImage, listItemClickHandler, products]);

    if (isLoading) {
        return (
            <Flex
                position="absolute"
                top="100%"
                w="100%"
                borderColor="#E4E9F2"
                borderWidth={1}
                overflow="hidden"
                mt={3}
                p={2}
                bg="white"
                justifyContent="center"
                zIndex={10}
            >
                <Box my={3} px={4}>
                    <Spinner mx="auto" />
                </Box>
            </Flex>
        );
    }

    return (
        <Flex
            position="absolute"
            top="100%"
            w="100%"
            borderColor="#E4E9F2"
            borderWidth={1}
            overflow="hidden"
            mt={3}
            zIndex={10}
        >
            <UnorderedList listStyleType="none" mx="none" bg="white" w="100%" p={2}>
                {products.length > 0 ? (
                    resultItemElements
                ) : (
                    <ListItem my={3} px={4} textAlign="center">
                        <FormattedMessage id="no-results" />
                    </ListItem>
                )}
            </UnorderedList>
        </Flex>
    );
};
