import { Box, Flex, Text } from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Locks } from '@/models/api/Locks';

import { useApp } from '../../../context/appContext';

export const SystemLockBar = () => {
    const { loginAllowed, orderingAllowed, locks } = useApp();
    const { basketLocked } = useShoppingCart();
    const [disableBasket, setDisableBasket] = useState<boolean>();

    const lockKeys = useMemo(() => Object.keys(locks) as Array<keyof Locks>, [locks]);

    const isAnyLock = useCallback(
        () => lockKeys.some((key) => !locks[key]) || basketLocked,
        [basketLocked, lockKeys, locks]
    );

    const hasAllLocks = useCallback(() => lockKeys.every((key) => !locks[key]), [lockKeys, locks]);

    useEffect(() => {
        if (!isAnyLock()) {
            document.body.classList.remove('has-locks');
            document.body.classList.remove('has-lock');
        } else if (hasAllLocks()) {
            document.body.classList.add('has-locks');
        } else {
            document.body.classList.add('has-lock');
        }

        const pageHeader = document.getElementById('page-header');

        if (pageHeader) {
            document.body.style.setProperty('--header-height', `${pageHeader.offsetHeight}px`);
        }
    }, [hasAllLocks, isAnyLock]);

    useEffect(() => {
        setDisableBasket(basketLocked);
    }, [basketLocked]);

    if (!isAnyLock() || typeof disableBasket === 'undefined') {
        return null;
    }

    return (
        <Flex
            bgColor="danger.500"
            color="white"
            textAlign="center"
            px={4}
            py={2}
            justifyContent="center"
            alignItems="center"
        >
            <Box>
                {!loginAllowed && (
                    <Text>
                        <FormattedMessage id="locks.loginLock" />
                    </Text>
                )}
                {!orderingAllowed && basketLocked === false && (
                    <Text>
                        <FormattedMessage id="locks.orderingLock" />
                    </Text>
                )}
                {basketLocked && (
                    <Text>
                        <FormattedMessage id="locks.orderingAndBasketLock" />
                    </Text>
                )}
            </Box>
        </Flex>
    );
};
