import { Box, Button, Checkbox, FormControl, FormLabel } from '@chakra-ui/react';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormControlError } from '@/components/FormControlError/FormControlError';
import NavLink from '@/components/UI/Links/NavLink';

import { ContactForm } from '@/models/api/Contact';
import { AppService } from '@/services';

import { ContactAgreementsProps } from './ContactAgreementsProps';

type Accordion = {
    accordion?: boolean;
};

export const ContactAgreements = (props: ContactAgreementsProps<ContactForm> & Accordion) => {
    const { formInstance, accordion } = props;
    const intl = useIntl();
    const { formState, register } = formInstance;
    const [more, setMore] = useState(false);

    const clubPrivacyPolicyFileLink = AppService.getClubPrivacyPolicyFileLink();

    const rodoControl = register('contactAgreement', {
        required: {
            value: true,
            message: 'field-is-required'
        }
    });

    const privacyPolicyFormattedMessage = useCallback(
        (messages: ReactNode[]) => {
            return (
                <NavLink color="brand.darker" href={clubPrivacyPolicyFileLink} isExternal>
                    {messages}
                </NavLink>
            );
        },
        [clubPrivacyPolicyFileLink]
    );

    const truncatedText = useMemo(() => {
        const fullText = intl.formatMessage({ id: 'contact.agreements' });
        const truncatedText = fullText.split(' ').slice(0, 8).join(' ').concat('...');

        return truncatedText;
    }, [intl]);

    return (
        <Box as="fieldset" whiteSpace="pre-line" textAlign="left">
            <FormControl
                isInvalid={!!formState.errors[rodoControl.name]}
                isRequired
                display="flex"
                alignItems="flex-start"
                columnGap={2}
            >
                <Checkbox {...rodoControl} />
                <Box>
                    <FormLabel fontSize="xs" color="black" margin={0}>
                        {!accordion && (
                            <FormattedMessage
                                id="contact.agreements"
                                values={{
                                    'data-privacy-policy-link': privacyPolicyFormattedMessage
                                }}
                            />
                        )}
                        {accordion && (
                            <>
                                {more ? (
                                    <FormattedMessage
                                        id="contact.agreements"
                                        values={{
                                            'data-privacy-policy-link':
                                                privacyPolicyFormattedMessage
                                        }}
                                    />
                                ) : (
                                    truncatedText
                                )}
                            </>
                        )}
                    </FormLabel>
                    {accordion && (
                        <Button
                            variant="link"
                            fontSize="xs"
                            fontWeight="normal"
                            colorScheme="brand"
                            minW={0}
                            onClick={() => setMore(!more)}
                        >
                            {more ? <FormattedMessage id="hide" /> : <FormattedMessage id="show" />}
                        </Button>
                    )}
                    <FormControlError
                        formState={formState}
                        control={rodoControl}
                    ></FormControlError>
                </Box>
            </FormControl>
        </Box>
    );
};
