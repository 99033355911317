import { extendTheme, Theme } from '@chakra-ui/react';
import radii from 'themes/default-theme/border-radius';

import breakpoints from './breakpoints';
import colors, { focusColor, primaryTextColor } from './colors';
import components from './components';
import { fontFamily, headingFontFamily } from './font';
import fontSizes from './font-sizes';
import sizes from './sizes';

const defaultTheme: Partial<Theme> = extendTheme({
    semanticTokens: {
        colors: {
            'chakra-body-text': { _light: primaryTextColor }
        }
    },
    shadows: {
        outline: `0 0 0 3px ${focusColor}`
    },
    styles: {
        global: {
            main: {
                flexGrow: 1
            },
            h1: {
                fontSize: fontSizes.h1,
                fontWeight: 500,
                marginBottom: 4
            },
            h2: {
                fontSize: fontSizes.h2,
                fontWeight: 500,
                marginBottom: 4
            },
            h3: {
                fontSize: fontSizes.h3,
                fontWeight: 500,
                marginBottom: 4
            },
            h4: {
                fontSize: fontSizes.h4,
                fontWeight: 400,
                marginBottom: 4
            },
            h5: {
                fontSize: fontSizes.h5,
                fontWeight: 400,
                marginBottom: 4
            },
            h6: {
                fontSize: fontSizes.h6,
                fontWeight: 400,
                marginBottom: 4
            }
        }
    },
    breakpoints,
    colors,
    fonts: {
        body: fontFamily,
        heading: headingFontFamily
    },
    fontSizes,
    sizes,
    components,
    radii
});

export default defaultTheme;
