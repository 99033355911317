import { Box, FlexProps } from '@chakra-ui/react';
import cn from 'clsx';
import Image, { ImageLoaderProps, ImageProps } from 'next/image';
import { useState } from 'react';

import Classes from './style.module.scss';

import shimmer from '@/utils/Shimmer';

import toBase64 from './toBase64';

/**
 *
 * @description Must set width using `w-` className
 * @param useSkeleton add background with pulse animation, don't use it if image is transparent
 */
export const NextImage = (props: ImageProps & FlexProps) => {
    const [isLoading, setLoading] = useState(true);
    const { src, width, height, alt, justifyContent, ...rest } = props;

    return (
        <Box
            pos="relative"
            width="100%"
            height="100%"
            className="group"
            overflow="hidden"
            justifyContent={justifyContent}
            {...rest}
        >
            <Image
                src={src}
                width={width}
                height={height}
                alt={alt}
                loader={customLoader}
                placeholder="blur"
                style={{
                    objectFit: 'cover',
                    objectPosition: 'center'
                }}
                blurDataURL={`data:image/svg+xml;base64,${toBase64(
                    shimmer(width ? +width : 700, height ? +height : 475)
                )}`}
                className={cn(Classes.nextImage, isLoading ? Classes.loading : Classes.loaded)}
                onLoad={() => setLoading(false)}
                {...rest}
            />
        </Box>
    );
};

const customLoader = ({ src, width, quality }: ImageLoaderProps): string => {
    return `${src}?w=${width}&q=${quality || 75}`;
};
