import { Box } from '@chakra-ui/react';
import { useLoadingProgress } from 'context/progressBarCotnext';
import { useShoppingCart } from 'context/shoppingCartContext';
import { deleteCookie, getCookie } from 'cookies-next';
import Router from 'next/router';
import { signOut, useSession } from 'next-auth/react';
import { PropsWithChildren, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';

import useIdleTimeout from '@/hooks/useIdleTimeout';
import { useToast } from '@/hooks/useToast';

import AddToCartModal from '@/components/AddToCartModal';
import { Breadcrumbs } from '@/components/Breadcrumbs/breadcrumbs';
import { useFavouriteButton } from '@/components/FavouriteButton/useFavouriteButton';
import { Footer } from '@/components/Footer';
import { GoTop } from '@/components/GoTop/GoTop';
import Header from '@/components/Header/Header';
import MessagesWindow from '@/components/MessagesWindow';
import { InactivekModal } from '@/components/Modals/InactiveModal';
import { LockModal } from '@/components/Modals/LockModal';
import { MessageModal } from '@/components/Modals/MessageModal';

import { CookieKeys } from '@/constants/cookie-keys';
import RoutePath from '@/constants/route-path';
import { MenuField } from '@/models/cms/Footer';
import { ProductIdProps } from '@/models/props/ShoppingCartContextProps';

import { useApp } from '../../context/appContext';
import { CrumbItem } from '../../context/breadcrumbsContext';
import { useCurrentUser } from '../../context/currentUserContext';

type LayoutProps = PropsWithChildren & {
    menu: MenuField[];
    socialLinks: MenuField[];
    crumbs?: CrumbItem[];
};

const PageLayout = (props: LayoutProps) => {
    const {
        currentUser,
        updateMoneySummary,
        updateUnreadNotificationsCounter,
        startUnreadNotificationsUpdating
    } = useCurrentUser();
    const { addProductToFavourites } = useFavouriteButton();
    const { data: session, status } = useSession();
    const { children, menu, socialLinks, crumbs } = props;
    const { start, done } = useLoadingProgress();
    const inactive = useIdleTimeout(1200000);
    const {
        basketNotifications,
        cartQuantity,
        clearBasketNotifications,
        clearCart,
        cartItemsUnlogged
    } = useShoppingCart();
    const { infoToast, warningToast, toast } = useToast();
    const intl = useIntl();
    const { updateLocks, orderingAllowed } = useApp();

    const onRouteChangeStart = useCallback(() => {
        updateLocks();
        start();
        if (currentUser) {
            updateMoneySummary();
            updateUnreadNotificationsCounter();
        }
    }, [currentUser, start, updateLocks, updateMoneySummary, updateUnreadNotificationsCounter]);
    const onRouteChangeComplete = useCallback(() => {
        setTimeout(() => {
            done();
        }, 1);
    }, [done]);

    const addProductFromCookie = useCallback(async () => {
        if (!session?.user) {
            return;
        }
        const productCode = getCookie(CookieKeys.addToFavouritesProductCode);
        if (!productCode) {
            return;
        }
        await addProductToFavourites(productCode as ProductIdProps);
        deleteCookie(CookieKeys.addToFavouritesProductCode);
    }, [addProductToFavourites, session?.user]);

    useEffect(() => {
        if (!orderingAllowed && cartQuantity > 0) {
            clearCart();
            return;
        }
    }, [cartQuantity, clearCart, orderingAllowed]);

    useEffect(() => {
        if (!currentUser) {
            return;
        }
        startUnreadNotificationsUpdating();
    }, [startUnreadNotificationsUpdating, currentUser]);

    useEffect(() => {
        if (!session) return;

        if (inactive) {
            sessionStorage.setItem('show-inactive-modal', 'true');
            signOut({
                callbackUrl: RoutePath.Home
            });
        }
    }, [session, inactive]);

    useEffect(() => {
        if (!session || !basketNotifications) return;

        if (basketNotifications?.giftsGained.length > 0 && !toast.isActive('giftsGained')) {
            infoToast({
                id: 'giftsGained',
                title: intl.formatMessage({ id: 'basket.update.gifts.gained' })
            });
        }

        if (basketNotifications?.giftsLost.length > 0 && !toast.isActive('giftLoast')) {
            warningToast({
                id: 'giftLoast',
                title: intl.formatMessage({ id: 'basket.update.gifts.lost' })
            });
        }

        clearBasketNotifications();
    }, [
        basketNotifications,
        session,
        warningToast,
        clearBasketNotifications,
        intl,
        infoToast,
        toast
    ]);

    useEffect(() => {
        if (status === 'authenticated') {
            document.body.classList.add('logged-in');
            document.body.setAttribute(
                'user-type',
                currentUser?.company ? 'company-user' : 'private-user'
            );
            updateUnreadNotificationsCounter();
        } else {
            document.body.classList.remove('logged-in');
            document.body.removeAttribute('user-type');
        }
    }, [status, currentUser, updateUnreadNotificationsCounter]);

    useEffect(() => {
        Router.events.on('routeChangeStart', onRouteChangeStart);
        Router.events.on('routeChangeComplete', onRouteChangeComplete);
        Router.events.on('routeChangeError', onRouteChangeComplete);

        return () => {
            Router.events.off('routeChangeStart', onRouteChangeStart);
            Router.events.off('routeChangeComplete', onRouteChangeComplete);
            Router.events.off('routeChangeError', onRouteChangeComplete);
        };
    }, [onRouteChangeStart, onRouteChangeComplete]);

    useEffect(() => {
        addProductFromCookie().then();
    }, [addProductFromCookie]);

    return (
        <>
            <Header />
            <Box as="main" pb={4}>
                <Breadcrumbs aria-label="breadcrumb" crumbs={crumbs} />
                {children}
            </Box>
            <Footer menu={menu} socialLinks={socialLinks} />
            <MessageModal />
            <LockModal />
            {session && <MessagesWindow />}
            {session && cartItemsUnlogged.length > 0 && <AddToCartModal />}
            {!session && <InactivekModal />}
            <GoTop />
        </>
    );
};

export default PageLayout;
