import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    List,
    ListItem,
    Text,
    useDisclosure,
    useMediaQuery
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import NavLink from '@/components/UI/Links/NavLink';

import breakpoints from '@/../themes/default-theme/breakpoints';
import { MenuLink } from '@/models/props/CurrentOfferProps';

export type SubmenuDrawerProps = {
    item: MenuLink;
    drawerClose: () => void;
    open: boolean;
};

export const SubmenuDrawer = (props: SubmenuDrawerProps) => {
    const { drawerClose, open, item } = props;
    const { translationKey, submenu } = item;
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.xl})`, {
        ssr: true,
        fallback: true
    });

    useEffect(() => {
        if (open) {
            onOpen();
        }
    }, [open, onOpen]);

    useEffect(() => {
        if (!isOpen) {
            drawerClose();
        }
    }, [isOpen, drawerClose]);

    return (
        <Drawer isOpen={isOpen && !isDesktop} onClose={onClose} size="full">
            <DrawerContent>
                <DrawerBody py={6} px={7}>
                    <Box>
                        <Button variant="link" onClick={onClose} color="inherit">
                            <ChevronLeftIcon />
                            <FormattedMessage id="back" />
                        </Button>
                        <Text
                            fontSize="h3"
                            fontWeight="semibold"
                            py={4}
                            borderBottomWidth={1}
                            borderTopColor="grey.200"
                        >
                            <FormattedMessage id={translationKey} />
                        </Text>
                    </Box>
                    <List display="grid">
                        {submenu?.map((submenuItem, index) => (
                            <ListItem key={index}>
                                <NavLink
                                    bgColor="transparent"
                                    href={submenuItem.href}
                                    skipRouter={submenuItem.skipRouter}
                                    display="block"
                                    variant="buttonFade"
                                    px={0}
                                    py={6}
                                    _hover={{
                                        bgColor: 'grey.100'
                                    }}
                                >
                                    <FormattedMessage id={submenuItem.translationKey} />
                                </NavLink>
                            </ListItem>
                        ))}
                    </List>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
