import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import classes from './submenuList.module.scss';

import { SubMenuItem } from '@/components/SubMenu/SubMenuItem';

import { MenuLink } from '@/models/props/CurrentOfferProps';

import { BracketOpenIcon } from '../Icons';

export const SubMenu = (props: { menu: MenuLink[] }) => {
    const { menu } = props;
    const [menuOpen, setMenuOpen] = useState(false);
    const [closeMenu, setCloseMenu] = useState(false);
    const [mouseOver, setMouseOver] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    const menuItems = menu.map((item, index) => (
        <SubMenuItem item={item} key={index} display="inline-block" />
    ));

    const menuOverHandle = useCallback(() => {
        setMouseOver(true);
    }, []);

    const menuLeaveHandle = useCallback(() => {
        setMouseOver(false);
    }, []);

    const onWheelHandles = useCallback(
        (close: () => void) => {
            if (menuOpen && !mouseOver) {
                close();
            }
        },
        [menuOpen, mouseOver]
    );

    return (
        <Menu
            closeOnSelect={false}
            onClose={() => {
                setMenuOpen(false);
                setCloseMenu(false);
                menuRef.current?.removeEventListener('mouseover', menuOverHandle);
                menuRef.current?.removeEventListener('mouseleave', menuLeaveHandle);
            }}
            onOpen={() => {
                setMenuOpen(true);
                menuRef.current?.addEventListener('mouseover', menuOverHandle);
                menuRef.current?.addEventListener('mouseleave', menuLeaveHandle);
            }}
            gutter={0}
        >
            {({ isOpen, onClose }) => (
                <>
                    <MenuButton
                        as={Button}
                        variant="link"
                        rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        fontSize={{ base: 16, '3xl': 18 }}
                        fontWeight="normal"
                        borderColor="grey.border"
                        isActive={isOpen}
                        px={0}
                        mr={{ base: 6 }}
                        color="black"
                        _hover={{ color: 'brand.main' }}
                        leftIcon={
                            <BracketOpenIcon
                                fill="transparent"
                                height="38px"
                                width="8px"
                                pointerEvents="none"
                                marginRight={2}
                            />
                        }
                    >
                        <FormattedMessage id="page.world-ovi.title" />
                    </MenuButton>
                    <Box
                        minWidth="100%"
                        pointerEvents="none"
                        position="fixed"
                        width="100vw"
                        height="100vh"
                        top="0"
                        left="0"
                    >
                        <MenuList
                            p={0}
                            shadow="none"
                            bg="none"
                            border="none"
                            overflow="hidden"
                            pt={6}
                            onClick={() => onClose()}
                            onWheel={() => onWheelHandles(onClose)}
                        >
                            <Flex
                                position="absolute"
                                justifyContent="flex-start"
                                flexDirection="column"
                                pointerEvents={closeMenu ? 'none' : 'auto'}
                                className={classes.worldList}
                                px={5}
                                py={6}
                                borderWidth={1}
                                borderColor="grey.border"
                                bg="white"
                            >
                                {menuItems}
                            </Flex>
                        </MenuList>
                    </Box>
                </>
            )}
        </Menu>
    );
};
