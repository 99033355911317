import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const UserIcon = (props: IconProps) => {
    return (
        <Icon
            viewBox="0 0 32 32"
            fill="none"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...props}
        >
            <path d="M10.668 9.83333C10.668 11.2478 11.2299 12.6044 12.2301 13.6046C13.2303 14.6048 14.5868 15.1667 16.0013 15.1667C17.4158 15.1667 18.7723 14.6048 19.7725 13.6046C20.7727 12.6044 21.3346 11.2478 21.3346 9.83333C21.3346 8.41885 20.7727 7.06229 19.7725 6.0621C18.7723 5.0619 17.4158 4.5 16.0013 4.5C14.5868 4.5 13.2303 5.0619 12.2301 6.0621C11.2299 7.06229 10.668 8.41885 10.668 9.83333Z" />
            <path d="M8 28.5V25.8333C8 24.4188 8.5619 23.0623 9.5621 22.0621C10.5623 21.0619 11.9188 20.5 13.3333 20.5H18.6667C20.0812 20.5 21.4377 21.0619 22.4379 22.0621C23.4381 23.0623 24 24.4188 24 25.8333V28.5" />
        </Icon>
    );
};
