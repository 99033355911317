import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const BracketCloseIcon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 10 38" {...props}>
            <path d="M0.263992 0L0 0.309203C4.51954 4.56341 7.62936 10.273 7.62936 19C7.62936 27.727 4.51426 33.4366 0 37.6908L0.263992 38C5.30623 34.0976 10 27.8549 10 19C10 10.1451 5.30623 3.89703 0.263992 0Z" />
        </Icon>
    );
};
