import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const Number4Icon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 200 287" {...props}>
            <path d="M160 236.2C160 242.067 159.867 247.8 159.6 253.4C159.6 258.733 159.2 263.267 158.4 267C163.467 266.733 168.667 266.6 174 266.6C179.6 266.333 184.533 266.067 188.8 265.8V287H84V269.4H86.8C94.8 268.867 101.067 267 105.6 263.8C110.133 260.333 113.333 255.4 115.2 249C117.067 242.6 118 234.333 118 224.2V211.4H0.8V185.8L143.2 0.599992H160V183.4L200 183.8V211.4H160V236.2ZM117.2 66.6L30.8 181.8L118 182.6V66.6H117.2Z" />
        </Icon>
    );
};
