import { Icon } from '@chakra-ui/icon';
import { IconProps } from '@chakra-ui/icons';

export const MoneyIcon = (props: IconProps) => {
    return (
        <Icon
            viewBox="0 0 18 19"
            fill="none"
            stroke="#302923"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...props}
        >
            <path d="M11.25 8.75V8.75667" />
            <path d="M3.87987 6.78348C3.55091 6.53066 3.29827 6.19181 3.14986 5.80438C3.00145 5.41695 2.96305 4.99603 3.0389 4.58814C3.11476 4.18025 3.30191 3.80128 3.57968 3.4931C3.85745 3.18493 4.21502 2.95956 4.61287 2.84189C5.01072 2.72423 5.43335 2.71886 5.83406 2.82638C6.23477 2.93389 6.59795 3.15011 6.88346 3.45112C7.16897 3.75214 7.36569 4.12624 7.45188 4.53207C7.53808 4.9379 7.51039 5.35966 7.37187 5.75073" />
            <path d="M12 3.5V6.35225C12.9282 6.88934 13.6349 7.73933 13.9935 8.75H14.9993C15.1982 8.75 15.3889 8.82902 15.5296 8.96967C15.6702 9.11032 15.7493 9.30109 15.7493 9.5V11C15.7493 11.1989 15.6702 11.3897 15.5296 11.5303C15.3889 11.671 15.1982 11.75 14.9993 11.75H13.9928C13.7408 12.4625 13.3125 13.1 12.7493 13.6047V15.125C12.7493 15.4234 12.6307 15.7095 12.4198 15.9205C12.2088 16.1315 11.9226 16.25 11.6243 16.25C11.3259 16.25 11.0397 16.1315 10.8288 15.9205C10.6178 15.7095 10.4993 15.4234 10.4993 15.125V14.6877C10.2514 14.7293 10.0006 14.7501 9.74926 14.75H6.74926C6.49797 14.7501 6.2471 14.7293 5.99926 14.6877V15.125C5.99926 15.4234 5.88073 15.7095 5.66976 15.9205C5.45878 16.1315 5.17263 16.25 4.87426 16.25C4.57589 16.25 4.28974 16.1315 4.07877 15.9205C3.86779 15.7095 3.74926 15.4234 3.74926 15.125V13.625V13.6047C3.06973 12.9972 2.59072 12.1977 2.37562 11.3119C2.16053 10.4261 2.21949 9.49593 2.54472 8.64442C2.86994 7.7929 3.44608 7.06023 4.19689 6.54337C4.94769 6.02652 5.83775 5.74985 6.74926 5.75H8.62426L11.9993 3.5H12Z" />
        </Icon>
    );
};
