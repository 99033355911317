import {
    Box,
    Button,
    DrawerBody,
    DrawerCloseButton,
    DrawerFooter,
    DrawerHeader,
    Flex,
    GridItem,
    SimpleGrid,
    Text
} from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import BasketTimeCheckout from '@/components/BasketTime/BasketTimeCheckout';
import { FormattedPrice } from '@/components/FormattedPrice/FormattedPrice';
import { H4 } from '@/components/Heading';
import { AlertCartDisable } from '@/components/ShoppingCart/AlertCartDisable';
import FetchingProductsSpinner from '@/components/ShoppingCart/FetchingProductsSpinner';
import { ProductItem } from '@/components/ShoppingCart/ProductItem';
import NavLink from '@/components/UI/Links/NavLink';

import BasketConfig from '@/constants/basket';
import RoutePath from '@/constants/route-path';
import { Product } from '@/models/api';
import { LocaleProps } from '@/models/props/LocalizationProps';
import { DisabledReason, GlobalProductProps } from '@/models/props/ProductCardProps';
import { ProductListProps } from '@/models/props/ProductListProps';
import { ProductIdProps } from '@/models/props/ShoppingCartContextProps';
import { AppService } from '@/services';
import { ProductService } from '@/services/ProductService';

function ShoppingCart({ onClose }: { onClose: (val: boolean) => void }) {
    const { cartItems, currentBasket, fetchingData, cartQuantity } = useShoppingCart();
    const currency = AppService.getInstanceCurrency();
    const intl = useIntl();
    const [products, setProducts] = useState<Product[]>([]);
    const [globalSettings] = useState<GlobalProductProps>();
    const [totalGrossCost, setTotalGrossCost] = useState<number>(0);
    const [totalGrossCostBeforeDiscount, setTotalGrossCostBeforeDiscount] = useState<number>(0);
    const { controls } = currentBasket || {};
    const { disabledReason } = controls || {};
    const obj = useMemo(
        () => ({
            intl,
            products,
            globalSettings,
            currentBasket
        }),
        [intl, products, globalSettings, currentBasket]
    );

    const basketIsDisabled = disabledReason === DisabledReason.PendingTransferPayment;

    const getProducts = useCallback(async () => {
        if (cartItems.length) {
            const codes: ProductIdProps[] = cartItems.map((item) => item.code);
            const list: ProductListProps = await ProductService.getProductsList({
                productCodes: codes,
                pageSize: BasketConfig.pageSize,
                locale: obj.intl.locale as LocaleProps
            });

            setProducts(list.items);
        }
    }, [cartItems, obj]);

    useEffect(() => {
        if (cartItems) getProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartItems]);

    useEffect(() => {
        const summary = currentBasket?.summary;
        if (summary) {
            const { totalGrossCost = '0', discountUsed = '0', refundMoneyUsed = '0' } = summary;
            const total =
                parseFloat(totalGrossCost.replace(',', '.')) +
                parseFloat(discountUsed.replace(',', '.')) +
                parseFloat(refundMoneyUsed.replace(',', '.'));

            setTotalGrossCost(total);
        }
    }, [currentBasket]);

    useEffect(() => {
        const shippingCost = currentBasket?.summary?.shippingCost?.replace(',', '.') || '0';
        const presentsCost = currentBasket?.summary?.presentsCost?.replace(',', '.') || '0';

        let total = parseFloat(shippingCost) * 100 + parseFloat(presentsCost) * 100;

        obj.products.map((item) => {
            const basketItem =
                obj.currentBasket && obj.currentBasket.products.find((i) => i.code === item.code);

            if (basketItem) {
                const quantity = basketItem.quantity * 100;
                const grossPriceBeforeDiscount = item.grossPriceBeforeDiscount?.replace(',', '.');
                const grossPrice = item.grossPrice.replace(',', '.');
                const priceWithoutDiscount = grossPriceBeforeDiscount
                    ? parseFloat(grossPriceBeforeDiscount) * quantity
                    : parseFloat(grossPrice) * quantity;

                total += priceWithoutDiscount;
            }
        });
        setTotalGrossCostBeforeDiscount(total / 100);
    }, [cartItems, currentBasket, obj]);

    const displayItems = useMemo(
        () =>
            obj.products.map((item, index) => {
                const basketItem =
                    obj.currentBasket &&
                    obj.currentBasket.products.find((i) => i.code === item.code);

                return (
                    basketItem && (
                        <Box key={index}>
                            <ProductItem key={item.code} product={item} basketItem={basketItem} />
                        </Box>
                    )
                );
            }),
        [obj]
    );

    return (
        <Flex
            flexDirection="column"
            overflow={{
                base: 'auto',
                lg: 'hidden'
            }}
            flex={1}
        >
            <DrawerHeader fontSize="base" p={0}>
                <Box minH={20} width="100%" px={{ base: 4, md: 6 }} pt={5} pb={2}>
                    <Flex alignItems="center" justifyContent="space-between" mb={2} w="100%">
                        <H4 mb={0}>
                            <FormattedMessage id="basket.long" /> ({cartQuantity})
                        </H4>
                        <DrawerCloseButton h="9" w={9} pos="static" />
                    </Flex>
                    {!!cartItems.length && !basketIsDisabled && <BasketTimeCheckout />}
                    <AlertCartDisable disableReason={controls?.disabledReason} />
                </Box>
            </DrawerHeader>

            <DrawerBody
                px={6}
                pt={6}
                pb={0}
                overflow="hidden"
                pos="relative"
                flexShrink={{
                    base: 0,
                    lg: 1
                }}
                flexBasis="auto"
            >
                <Box className="custom-scroll" h="100%" overflow="auto" mb={6}>
                    <Flex
                        flexDirection="column"
                        rowGap={8}
                        h="100%"
                        pointerEvents={fetchingData ? 'none' : 'auto'}
                    >
                        {cartItems.length ? (
                            displayItems
                        ) : (
                            <Flex
                                alignItems="center"
                                justifyContent="center"
                                borderWidth="1px"
                                borderColor="grey.border"
                                h="100%"
                                textAlign="center"
                            >
                                <Text textColor="grey.600">
                                    <FormattedMessage id="basket-empty" />
                                </Text>
                            </Flex>
                        )}
                    </Flex>
                </Box>
                {fetchingData && <FetchingProductsSpinner />}
            </DrawerBody>

            {!!currentBasket?.products.length && !!cartItems.length && (
                <DrawerFooter
                    display="block"
                    py={4}
                    px={{ base: 4, md: 6 }}
                    boxShadow="0 -10px 25px 0 #00000010"
                >
                    <Flex flexDirection="column" rowGap={6}>
                        {!!currentBasket?.products.length && !!cartItems.length && (
                            <Flex flexDirection="column" alignItems="stretch" rowGap={3} mt="auto">
                                <SimpleGrid columns={2}>
                                    <GridItem display="flex" alignItems="center">
                                        <Text fontWeight="medium" fontSize="md">
                                            <FormattedMessage id="total-points" />
                                        </Text>
                                    </GridItem>
                                    {currentBasket.summary && (
                                        <GridItem textAlign="end">
                                            <Text fontSize="lg">
                                                {currentBasket.summary.points}
                                            </Text>
                                        </GridItem>
                                    )}
                                </SimpleGrid>

                                <SimpleGrid columns={2}>
                                    <GridItem display="flex" alignItems="center">
                                        <Text fontWeight="medium" fontSize="md">
                                            <FormattedMessage id="order.total-value.brutto" />:
                                        </Text>
                                    </GridItem>
                                    {!!currentBasket.products.length && currentBasket.summary && (
                                        <GridItem>
                                            <Flex
                                                columnGap="3"
                                                alignItems="center"
                                                justifyContent="flex-end"
                                            >
                                                <Text textDecoration="line-through" fontSize="2xl">
                                                    <FormattedPrice
                                                        value={totalGrossCostBeforeDiscount}
                                                        currency={currency}
                                                    />
                                                </Text>
                                                <Text fontSize="2xl" fontWeight="medium">
                                                    <FormattedPrice
                                                        value={totalGrossCost}
                                                        currency={currency}
                                                    />
                                                </Text>
                                            </Flex>
                                        </GridItem>
                                    )}
                                </SimpleGrid>
                            </Flex>
                        )}

                        <Flex flexDirection="column" alignItems="stretch" rowGap={4}>
                            <NavLink
                                href={RoutePath.Checkout}
                                variant="buttonBrand"
                                fontWeight="medium"
                                textAlign="center"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                minH="46px"
                            >
                                <FormattedMessage id="go-to-checkout" />
                            </NavLink>
                            <Button variant="outline" minH="46px" onClick={() => onClose(true)}>
                                <FormattedMessage id="continue-shopping" />
                            </Button>
                        </Flex>
                    </Flex>
                </DrawerFooter>
            )}
        </Flex>
    );
}

export default ShoppingCart;
