import { VisuallyHidden } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';

import { LogoIconProps } from '@/components/Icons/LogoIcon';

import NavLink from './NavLink';

export const BrandLink = (props: LogoIconProps) => {
    const router = useRouter();
    const { locale } = router;
    const href = props.href || '';

    return (
        <NavLink
            href={href}
            skipRouter={true}
            locale={locale}
            display="flex"
            justifyContent="center"
        >
            <VisuallyHidden>
                <FormattedMessage id="go-to-homepage" />
            </VisuallyHidden>
            <FormattedMessage id="main-page" />
            {/* <LogoIcon fontSize="9.25rem" h="auto" pointerEvents="none" maxW="100%" {...props} /> */}
        </NavLink>
    );
};
