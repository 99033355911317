import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    checkboxAnatomy.keys
);

const baseStyle = definePartsStyle({
    control: {
        rounded: 0,
        marginTop: '0.1rem',
        _invalid: {
            borderColor: 'danger.500'
        }
    },
    label: {
        marginLeft: '1.125rem',
        fontSize: { base: '12px', md: 'initial' }
    }
});

const variantBgWhite = definePartsStyle({
    control: {
        bgColor: 'white',
        borderColor: 'grey.400',
        borderWidth: '1px'
    }
});

const variants = {
    bgWhite: variantBgWhite
};

export const Checkbox = defineMultiStyleConfig({
    baseStyle,
    variants,
    defaultProps: {
        colorScheme: 'brand'
    }
});
