import { Container, Flex, Link, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { FooterProps } from '@/models/props/NavigationFooterProps';

import { FooterNavigation } from './FooterNavigation';
import { SocialMedia } from './SocialMedia';

export const Footer = (props: FooterProps) => {
    const { menu, socialLinks } = props;
    const odrUrl = 'https://ec.europa.eu/consumers/odr/';
    const url = (
        <Link href={odrUrl} isExternal={true} rel="noopener nofollow">
            {odrUrl}
        </Link>
    );

    return (
        <Container
            as="footer"
            maxW="full"
            backgroundColor="grey.100"
            className="page-footer"
            py={14}
        >
            <Flex
                maxW="container.3xl"
                flexDirection="column"
                justifyContent="flex-start"
                rowGap={8}
                mx="auto"
            >
                <Flex
                    flexDirection={{ base: 'column', lg: 'row' }}
                    justifyContent={{ base: 'center', lg: 'space-between' }}
                    alignItems="center"
                    rowGap={9}
                >
                    <FooterNavigation menu={menu} />
                    <SocialMedia socialLinks={socialLinks} />
                </Flex>
                <Text
                    color="black"
                    fontSize="0.75rem"
                    lineHeight="1.22"
                    textAlign={{ base: 'center', lg: 'left' }}
                >
                    <FormattedMessage
                        id="footer-info-text"
                        values={{
                            address: url
                        }}
                    />
                </Text>
            </Flex>
        </Container>
    );
};
