import { Flex, Text } from '@chakra-ui/react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { CatalogIcon } from '@/components/Icons';

type CatalogBoxProps = {
    endDate?: number;
};

export const CatalogBox = ({ endDate }: CatalogBoxProps) => {
    return (
        <Flex
            flexWrap="wrap"
            columnGap={2}
            alignItems="center"
            justifyContent={{ sm: 'center' }}
            textAlign="center"
            mr={2}
        >
            <Flex alignItems="center" columnGap={2}>
                <CatalogIcon w={4} h={4} />
                <Text color="black" whiteSpace="nowrap">
                    <FormattedMessage id="catalog-is-valid-until" />
                </Text>
            </Flex>
            <Text color="black">
                {endDate ? (
                    <FormattedDate
                        value={endDate}
                        timeZone="UTC"
                        dateStyle="short"
                        timeStyle="medium"
                    />
                ) : (
                    '-'
                )}
            </Text>
        </Flex>
    );
};
