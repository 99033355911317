import { Flex, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import BasketTime from '@/components/BasketTime';
import { CartIcon } from '@/components/Icons';

function BasketTimeCheckout() {
    return (
        <Flex alignItems="center" gap="10px">
            <CartIcon />
            <Text as="span" fontSize="12px" fontWeight="medium" lineHeight={1}>
                <FormattedMessage id="expire" /> <BasketTime />
            </Text>
        </Flex>
    );
}

export default BasketTimeCheckout;
