import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const PercentIcon = (props: IconProps) => {
    return (
        <Icon
            viewBox="0 0 32 32"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...props}
        >
            <path d="M21.3359 22.6663C21.3359 23.02 21.4764 23.3591 21.7265 23.6092C21.9765 23.8592 22.3156 23.9997 22.6693 23.9997C23.0229 23.9997 23.362 23.8592 23.6121 23.6092C23.8621 23.3591 24.0026 23.02 24.0026 22.6663C24.0026 22.3127 23.8621 21.9736 23.6121 21.7235C23.362 21.4735 23.0229 21.333 22.6693 21.333C22.3156 21.333 21.9765 21.4735 21.7265 21.7235C21.4764 21.9736 21.3359 22.3127 21.3359 22.6663Z" />
            <path d="M8 9.33333C8 9.68696 8.14048 10.0261 8.39052 10.2761C8.64057 10.5262 8.97971 10.6667 9.33333 10.6667C9.68696 10.6667 10.0261 10.5262 10.2761 10.2761C10.5262 10.0261 10.6667 9.68696 10.6667 9.33333C10.6667 8.97971 10.5262 8.64057 10.2761 8.39052C10.0261 8.14048 9.68696 8 9.33333 8C8.97971 8 8.64057 8.14048 8.39052 8.39052C8.14048 8.64057 8 8.97971 8 9.33333Z" />
            <path d="M8 24L24 8" />
        </Icon>
    );
};
