import { Button, ButtonProps, VisuallyHidden } from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { FormattedMessage } from 'react-intl';

import { BasketIcon } from '@/components/Icons';
import CartQuantity from '@/components/ShoppingCart/CartQuantity';

function ButtonCartUnlogged(props: ButtonProps) {
    const { ...rest } = props;
    const { cartQuantityUnlogged } = useShoppingCart();

    return (
        <Button display="flex" variant="ghost" border="none" p={2} {...rest}>
            <CartQuantity quantity={cartQuantityUnlogged} />
            {cartQuantityUnlogged === 0 && (
                <>
                    <BasketIcon
                        height={{ base: 8, xl: 7, '3xl': 8 }}
                        width={{ base: 8, xl: 7, '3xl': 8 }}
                        pointerEvents="none"
                    />
                    <VisuallyHidden>
                        <FormattedMessage id="basket" />
                    </VisuallyHidden>
                </>
            )}
        </Button>
    );
}

export default ButtonCartUnlogged;
