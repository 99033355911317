import { AlertDescription, AlertTitle, Box, CloseButton, ToastProps } from '@chakra-ui/react';
import { useIntl } from 'react-intl';

import { Alert, AlertIcon } from '@/components/UI';

export function Toast(props: ToastProps) {
    const {
        status,
        variant = 'solid',
        id,
        title,
        isClosable,
        onClose,
        description,
        colorScheme,
        icon
    } = props;

    const intl = useIntl();

    const ids = id
        ? {
              root: `toast-${id}`,
              title: `toast-${id}-title`,
              description: `toast-${id}-description`
          }
        : undefined;

    return (
        <Alert
            addRole={false}
            status={status}
            variant={variant}
            id={ids?.root}
            alignItems="start"
            boxShadow="lg"
            paddingEnd={8}
            textAlign="start"
            width="auto"
            colorScheme={colorScheme}
        >
            <AlertIcon>{icon}</AlertIcon>
            <Box flex="1" maxWidth="100%">
                {title && <AlertTitle id={ids?.title}>{title}</AlertTitle>}
                {description && (
                    <AlertDescription id={ids?.description} display="block">
                        {description}
                    </AlertDescription>
                )}
            </Box>
            {isClosable && (
                <CloseButton
                    aria-label={intl.formatMessage({ id: 'close' })}
                    size="sm"
                    onClick={onClose}
                    position="absolute"
                    insetEnd={1}
                    top={1}
                />
            )}
        </Alert>
    );
}
