import { Box } from '@chakra-ui/react';

import { BasketIcon } from '@/components/Icons';

function CartQuantity(props: { quantity: number }) {
    const { quantity } = props;

    return (
        <>
            {quantity > 0 && (
                <Box position="relative">
                    <BasketIcon
                        height={{ base: 8, xl: 7, '3xl': 8 }}
                        width={{ base: 8, xl: 7, '3xl': 8 }}
                        pointerEvents="none"
                    />
                    <Box
                        as="span"
                        color="white"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position="absolute"
                        h={17}
                        minWidth={17}
                        top={1}
                        right={-1}
                        fontWeight="medium"
                        fontSize="10px"
                        bgColor="grey.950"
                        p={0}
                        borderRadius="50%"
                    >
                        {quantity}
                    </Box>
                </Box>
            )}
        </>
    );
}

export default CartQuantity;
