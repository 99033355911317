import { ListItem, MenuItem, MenuItemProps } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import NavLink from '@/components/UI/Links/NavLink';

import { MenuLink } from '@/models/props/CurrentOfferProps';

export const SubMenuItem = (
    props: {
        item: MenuLink;
    } & MenuItemProps
) => {
    const { item, ...rest } = props;

    return (
        <MenuItem
            as={ListItem}
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            alignItems="center"
            p={0}
            closeOnSelect={true}
            bg="white"
            w="100%"
            _hover={{ bg: 'accent.200' }}
            {...rest}
        >
            <NavLink
                href={item.href}
                skipRouter={item.skipRouter}
                flex="1"
                display="block"
                position="relative"
                px={4}
                py={2}
                wordBreak="break-word"
                _hover={{ textDecoration: 'none' }}
            >
                <FormattedMessage id={item.translationKey} />
            </NavLink>
        </MenuItem>
    );
};
