import {
    Box,
    Button,
    DrawerBody,
    DrawerCloseButton,
    DrawerFooter,
    DrawerHeader,
    Flex,
    GridItem,
    SimpleGrid,
    Text
} from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormattedPrice } from '@/components/FormattedPrice/FormattedPrice';
import { H4 } from '@/components/Heading';
import { PercentIcon } from '@/components/Icons';
import FetchingProductsSpinner from '@/components/ShoppingCart/FetchingProductsSpinner';
import { ProductItemUnlogged } from '@/components/ShoppingCart/ProductItemUnlogged';
import NavLink from '@/components/UI/Links/NavLink';

import BasketConfig from '@/constants/basket';
import RoutePath from '@/constants/route-path';
import { Product } from '@/models/api';
import { LocaleProps } from '@/models/props/LocalizationProps';
import { GlobalProductProps } from '@/models/props/ProductCardProps';
import { ProductListProps } from '@/models/props/ProductListProps';
import { ProductIdProps } from '@/models/props/ShoppingCartContextProps';
import { AppService } from '@/services';
import { ProductService } from '@/services/ProductService';

function ShoppingCart({ onClose }: { onClose: (val: boolean) => void }) {
    const { cartItemsUnlogged, fetchingData, basketUnlogged, cartQuantityUnlogged } =
        useShoppingCart();
    const currency = AppService.getInstanceCurrency();
    const intl = useIntl();
    const [products, setProducts] = useState<Product[]>([]);
    const [globalSettings] = useState<GlobalProductProps>();
    const obj = useMemo(
        () => ({
            intl,
            products,
            globalSettings
        }),
        [intl, products, globalSettings]
    );

    const getProducts = useCallback(async () => {
        if (cartItemsUnlogged.length) {
            const codes: ProductIdProps[] = cartItemsUnlogged.map((item) => item.code);
            const list: ProductListProps = await ProductService.getProductsList({
                productCodes: codes,
                pageSize: BasketConfig.pageSize,
                locale: obj.intl.locale as LocaleProps
            });

            setProducts(list.items);
        }
    }, [cartItemsUnlogged, obj]);

    useEffect(() => {
        if (cartItemsUnlogged) getProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartItemsUnlogged]);

    const displayItems = useMemo(
        () =>
            obj.products.map((product, index) => {
                return (
                    <Box key={index}>
                        <ProductItemUnlogged
                            key={product.code}
                            product={product}
                            basket={cartItemsUnlogged}
                        />
                    </Box>
                );
            }),
        [cartItemsUnlogged, obj]
    );

    return (
        <Flex
            flexDirection="column"
            overflow={{
                base: 'auto',
                lg: 'hidden'
            }}
            flex={1}
        >
            <DrawerHeader fontSize="base" p={0}>
                <Box px={{ base: 4, md: 6 }} pt={5} pb={2}>
                    <Flex alignItems="center" justifyContent="space-between" w="100%">
                        <H4 mb={0}>
                            <FormattedMessage id="basket.long" /> ({cartQuantityUnlogged})
                        </H4>
                        <DrawerCloseButton
                            border="1px"
                            borderColor="grey.border"
                            h="9"
                            w={9}
                            pos="static"
                        />
                    </Flex>
                </Box>
            </DrawerHeader>

            <DrawerBody
                px={6}
                pt={6}
                pb={0}
                overflow="hidden"
                pos="relative"
                flexShrink={{
                    base: 0,
                    lg: 1
                }}
                flexBasis="auto"
            >
                <Box className="custom-scroll" h="100%" overflow="auto" pb={2}>
                    <Flex
                        flexDirection="column"
                        rowGap={8}
                        h="100%"
                        pointerEvents={fetchingData ? 'none' : 'auto'}
                    >
                        {cartItemsUnlogged.length ? (
                            displayItems
                        ) : (
                            <Flex
                                alignItems="center"
                                justifyContent="center"
                                borderWidth="1px"
                                borderColor="grey.border"
                                h="100%"
                                textAlign="center"
                            >
                                <Text textColor="grey.600">
                                    <FormattedMessage id="basket-empty" />
                                </Text>
                            </Flex>
                        )}
                    </Flex>
                </Box>
                {fetchingData && <FetchingProductsSpinner />}
            </DrawerBody>

            {!!cartItemsUnlogged.length && (
                <DrawerFooter
                    display="block"
                    py={4}
                    px={{ base: 4, md: 6 }}
                    boxShadow="0 -10px 25px 0 #00000010"
                >
                    <Flex flexDirection="column" alignItems="stretch" rowGap={6} mt="auto">
                        <Box>
                            <SimpleGrid columns={2} w="100%" py="6px">
                                <GridItem display="flex" alignItems="center">
                                    <Text fontWeight="medium" fontSize="md">
                                        <FormattedMessage id="order.total-value.brutto" />:
                                    </Text>
                                </GridItem>
                                {!!cartItemsUnlogged.length && basketUnlogged && (
                                    <GridItem textAlign="end">
                                        <Text fontSize="x-large" fontWeight="medium">
                                            <FormattedPrice
                                                value={basketUnlogged.total}
                                                currency={currency}
                                            />
                                        </Text>
                                    </GridItem>
                                )}
                            </SimpleGrid>

                            <Box bgColor="accent.200" py={3} pr={6} pl={4} w="full">
                                <Flex columnGap={2} alignItems="center">
                                    <PercentIcon fontSize="2rem" />
                                    <NavLink href={RoutePath.Register} variant="underlineBrand">
                                        {intl.formatMessage({
                                            id: 'create-account-to-pay-cheaper'
                                        })}
                                    </NavLink>
                                </Flex>
                            </Box>
                        </Box>

                        <Flex flexDirection="column" alignItems="stretch" rowGap={4}>
                            <NavLink
                                href={RoutePath.Checkout}
                                variant="buttonBrand"
                                fontWeight="medium"
                                textAlign="center"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                minH="46px"
                            >
                                <FormattedMessage id="go-to-checkout" />
                            </NavLink>
                            <Button variant="outline" minH="46px" onClick={() => onClose(true)}>
                                <FormattedMessage id="continue-shopping" />
                            </Button>
                        </Flex>
                    </Flex>
                </DrawerFooter>
            )}
        </Flex>
    );
}

export default ShoppingCart;
