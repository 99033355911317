const sizes = {
    container: {
        xs: '31rem',
        sm: '40rem',
        md: '48rem',
        lg: '64rem',
        xl: '75rem',
        '2xl': '87.25rem',
        '3xl': '105rem'
    }
};

export default sizes;
