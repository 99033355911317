import { Icon, IconProps } from '@chakra-ui/react';

export const TickIcon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 20 20" {...props}>
            <path d="M6.01884 0.742175C7.20176 0.252191 8.46961 0 9.75 0C11.0304 0 12.2982 0.252191 13.4812 0.742175C14.6641 1.23216 15.7389 1.95034 16.6443 2.85571C17.5497 3.76108 18.2678 4.83591 18.7578 6.01884C19.2478 7.20176 19.5 8.46961 19.5 9.75C19.5 11.0304 19.2478 12.2982 18.7578 13.4812C18.2678 14.6641 17.5497 15.7389 16.6443 16.6443C15.7389 17.5497 14.6641 18.2678 13.4812 18.7578C12.2982 19.2478 11.0304 19.5 9.75 19.5C8.46961 19.5 7.20176 19.2478 6.01884 18.7578C4.83591 18.2678 3.76108 17.5497 2.85571 16.6443C1.95034 15.7389 1.23216 14.6641 0.742174 13.4812C0.252191 12.2982 0 11.0304 0 9.75C0 8.46961 0.252191 7.20176 0.742174 6.01884C1.23216 4.83591 1.95034 3.76108 2.85571 2.85571C3.76108 1.95034 4.83591 1.23216 6.01884 0.742175ZM9.75 1.5C8.66659 1.5 7.5938 1.71339 6.59286 2.12799C5.59193 2.5426 4.68245 3.15029 3.91637 3.91637C3.15029 4.68245 2.54259 5.59193 2.12799 6.59286C1.71339 7.5938 1.5 8.66659 1.5 9.75C1.5 10.8334 1.71339 11.9062 2.12799 12.9071C2.54259 13.9081 3.15029 14.8175 3.91637 15.5836C4.68245 16.3497 5.59193 16.9574 6.59286 17.372C7.5938 17.7866 8.66659 18 9.75 18C10.8334 18 11.9062 17.7866 12.9071 17.372C13.9081 16.9574 14.8175 16.3497 15.5836 15.5836C16.3497 14.8175 16.9574 13.9081 17.372 12.9071C17.7866 11.9062 18 10.8334 18 9.75C18 8.66659 17.7866 7.5938 17.372 6.59286C16.9574 5.59193 16.3497 4.68245 15.5836 3.91637C14.8175 3.15029 13.9081 2.5426 12.9071 2.12799C11.9062 1.71339 10.8334 1.5 9.75 1.5Z" />
            <path d="M13.2803 7.21967C13.5732 7.51256 13.5732 7.98744 13.2803 8.28033L9.28033 12.2803C8.98744 12.5732 8.51256 12.5732 8.21967 12.2803L6.21967 10.2803C5.92678 9.98744 5.92678 9.51256 6.21967 9.21967C6.51256 8.92678 6.98744 8.92678 7.28033 9.21967L8.75 10.6893L12.2197 7.21967C12.5126 6.92678 12.9874 6.92678 13.2803 7.21967Z" />
        </Icon>
    );
};
