import { Icon } from '@chakra-ui/icon';
import { IconProps } from '@chakra-ui/icons';

export const CatalogIcon = (props: IconProps) => {
    return (
        <Icon
            viewBox="0 0 16 17"
            fill="none"
            stroke="#302923"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...props}
        >
            <path d="M2 13.1666C2.91212 12.64 3.94678 12.3628 5 12.3628C6.05322 12.3628 7.08788 12.64 8 13.1666C8.91212 12.64 9.94678 12.3628 11 12.3628C12.0532 12.3628 13.0879 12.64 14 13.1666" />
            <path d="M2 4.49989C2.91212 3.97328 3.94678 3.69604 5 3.69604C6.05322 3.69604 7.08788 3.97328 8 4.49989C8.91212 3.97328 9.94678 3.69604 11 3.69604C12.0532 3.69604 13.0879 3.97328 14 4.49989" />
            <path d="M2 4.5V13.1667" />
            <path d="M8 4.5V13.1667" />
            <path d="M14 4.5V13.1667" />
        </Icon>
    );
};
