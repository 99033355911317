import { ListItem, ListProps, UnorderedList } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';

import { BracketCloseIcon, BracketOpenIcon } from '@/components/Icons';
import { SubMenu } from '@/components/SubMenu/SubMenu';
import NavLink from '@/components/UI/Links/NavLink';

import { CurrentOfferProps } from '@/models/props/CurrentOfferProps';

export const CurrentOffer = (props: CurrentOfferProps & ListProps) => {
    const { menu, ...rest } = props;
    const router = useRouter();

    const menuItems = menu.map((item, index) => {
        const { href, translationKey, submenu, skipRouter } = item;
        const isActive = router.asPath === `${href}/`;

        return submenu ? (
            <SubMenu key={index} menu={submenu} />
        ) : (
            <ListItem key={index} display="inline-block">
                <NavLink
                    href={href}
                    skipRouter={skipRouter}
                    whiteSpace="nowrap"
                    fontSize={{ base: 15, '2xl': 16, '3xl': 18 }}
                    _hover={{ color: 'brand.main' }}
                    leftIcon={() => (
                        <BracketOpenIcon
                            fill={isActive ? 'brand.main' : 'transparent'}
                            height="38px"
                            width="8px"
                            pointerEvents="none"
                            marginRight={2}
                        />
                    )}
                    rightIcon={() => (
                        <BracketCloseIcon
                            fill={isActive ? 'brand.main' : 'transparent'}
                            height="38px"
                            width="8px"
                            pointerEvents="none"
                            marginLeft={2}
                        />
                    )}
                >
                    <FormattedMessage id={translationKey} />
                </NavLink>
            </ListItem>
        );
    });

    return (
        <>
            {menu.length && (
                <UnorderedList {...rest} pos="relative" mr={6} display="flex">
                    {menuItems}
                </UnorderedList>
            )}
        </>
    );
};
