import { ComponentStyleConfig } from '@chakra-ui/react';

export const Link: ComponentStyleConfig = {
    variants: {
        underlineBrand: {
            color: 'inherit',
            fontWeight: 'normal',
            width: 'max-content',
            textUnderlineOffset: '5px',
            textDecoration: 'underline',
            textDecorationThickness: '1px',
            textDecorationColor: 'brand.darker',
            _hover: {
                fontWeight: 'medium',
                textDecorationThickness: '1px',
                textDecorationColor: 'grey.main',
                letterSpacing: '-0.135px'
            }
        },
        boldedBrand: {
            color: 'brand.darker',
            fontWeight: 'semibold',
            width: 'max-content'
        },
        buttonFade: {
            bg: 'grey.200',
            px: '4',
            py: '3',
            borderRadius: 0,
            _hover: {
                bg: 'grey.300',
                textDecoration: 'none'
            }
        },
        buttonBrand: {
            bg: 'brand.darker',
            color: 'white',
            px: '6',
            py: '3',
            borderRadius: 0,
            _hover: {
                bg: 'brand.darkest',
                textDecoration: 'none'
            }
        },
        buttonOutline: {
            borderRadius: 0,
            borderColor: 'brand.darker',
            borderWidth: '1px',
            bgColor: 'white',
            color: 'black',
            fontWeight: 'medium',
            px: 6,
            py: 0,
            h: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            _hover: {
                borderColor: 'grey.main',
                bgColor: 'white',
                textDecoration: 'none'
            }
        }
    },
    defaultProps: {
        colorScheme: 'brand'
    }
};
