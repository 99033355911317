import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export type LogoIconProps = IconProps;

export const LogoIcon = (props: LogoIconProps) => {
    return (
        <Icon viewBox="0 0 629.62 193.22" {...props}>
            <g style={{ isolation: 'isolate' }}>
                <g>
                    <g>
                        <g>
                            <path d="m299.44,86.6h43.93v2.26h-46.44V.99h2.51v85.61Z" />
                            <path d="m391.85.99h2.51v87.87h-2.51V.99Z" />
                            <path d="m451.53.99h1.25l65.27,82.34V.99h2.51v87.87h-1.26L454.04,6.51v82.34h-2.51V.99Z" />
                            <path d="m623.23.99v2.26h-42.68v35.4h33.89v2.26h-33.89v45.69h43.93v2.26h-46.44V.99h45.19Z" />
                        </g>
                        <path d="m51.31,89.85c-31.3-.42-46.6-21.7-46.6-45.62C4.71,17.25,26.69,1.39,46.86.14v1.25c-2.36.42-6.54,1.39-9.32,2.92-10.43,5.56-17.8,17.11-17.8,38.39,0,19.75,6.68,36.86,21.14,43.26,3.2,1.39,6.95,2.36,10.43,2.64v1.25ZM52.84,0c31.3.42,46.6,21.7,46.6,45.62,0,26.98-21.98,42.7-42.15,44.09v-1.25c2.36-.42,6.54-1.39,9.32-2.92,10.43-5.56,17.8-17.11,17.8-38.39,0-19.75-6.68-36.86-21.14-43.26-3.2-1.39-6.95-2.36-10.43-2.64V0Z" />
                        <path d="m164.06,72.75l-6.82,16.97h-1.11l-27.82-70.66c-4.73-11.68-9.46-16-17.39-16.83v-1.11h41.31v1.11c-7.51.42-10.99,2.64-10.99,7.93,0,2.92,1.39,6.4,3.2,10.99l19.61,51.6ZM206.34,1.11v1.11c-5.98,1.11-10.71,3.2-14.74,7.09-4.03,3.89-7.37,9.6-10.71,17.66l-1.11-.56c.97-3.06,2.64-11.82,2.64-15.72,0-6.26-5.42-8.07-10.85-8.48v-1.11h34.77Z" />
                        <path d="m231.94,18.36c0-11.68-2.92-15.02-12.38-16.13v-1.11h38.39v1.11c-9.46,1.11-12.38,4.45-12.38,16.13v53.13c0,11.68,2.92,15.02,12.38,16.13v1.11h-38.39v-1.11c9.46-1.11,12.38-4.45,12.38-16.13V18.36Z" />
                    </g>
                    <g style={{ mixBlendMode: 'multiply' }}>
                        <text
                            style={{
                                fontFamily: 'Montserrat',
                                fontWeight: '200',
                                fontSize: '72.79px'
                            }}
                            transform="translate(0 174.36)"
                        >
                            <tspan style={{ letterSpacing: '-.01em' }} x="0" y="0">
                                N
                            </tspan>
                            <tspan style={{ letterSpacing: '-.02em' }} x="58.52" y="0">
                                o
                            </tspan>
                            <tspan style={{ letterSpacing: '.03em' }} x="101.31" y="0">
                                t{' '}
                            </tspan>
                            <tspan style={{ letterSpacing: '-.03em' }} x="151.39" y="0">
                                a
                            </tspan>
                            <tspan style={{ letterSpacing: '-.03em' }} x="191.09" y="0">
                                n
                            </tspan>
                            <tspan style={{ letterSpacing: '0em' }} x="237.4" y="0">
                                y
                            </tspan>
                            <tspan style={{ letterSpacing: '0em' }} x="274.18" y="0">
                                b
                            </tspan>
                            <tspan style={{ letterSpacing: '0em' }} x="323.38" y="0">
                                o
                            </tspan>
                            <tspan style={{ letterSpacing: '0em' }} x="368.11" y="0">
                                d
                            </tspan>
                            <tspan style={{ letterSpacing: '-.06em' }} x="416.75" y="0">
                                y
                            </tspan>
                            <tspan style={{ letterSpacing: '-.02em' }} x="449.49" y="0">
                                .{' '}
                            </tspan>
                            <tspan style={{ letterSpacing: '-.08em' }} x="484.58" y="0">
                                Y
                            </tspan>
                            <tspan style={{ letterSpacing: '-.01em' }} x="523.36" y="0">
                                o
                            </tspan>
                            <tspan style={{ letterSpacing: '0em' }} x="566.74" y="0">
                                u
                            </tspan>
                            <tspan style={{ letterSpacing: '.03em' }} x="614.55" y="0">
                                .
                            </tspan>
                        </text>
                    </g>
                </g>
            </g>
        </Icon>
    );
};
