import { Box, Flex } from '@chakra-ui/react';
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';

import { NextImage } from '@/components/Image';

import { Product } from '@/models/api';
import { StrapiUtilsService } from '@/services/StrapiUtilsService';

type ImageProps = {
    height?: number;
    width?: number;
};

function ProductImage({
    mainImage,
    title,
    width,
    height
}: Pick<Product, 'mainImage' | 'title'> & ImageProps) {
    const [imageurl, setImageUrl] = useState<string>();
    const [imgLoading, setImgLoading] = useState<boolean>(false);
    const [imageWidth, setImageWidth] = useState<number>(width || 120);
    const obj = useMemo(
        () => ({ mainImage, width, height, title }),
        [mainImage, width, height, title]
    );
    const imgProps: CSSProperties = useMemo(
        () => ({
            height: '100%'
        }),
        []
    );
    const nonImageProps: CSSProperties = useMemo(
        () => ({
            maxWidth: 'calc(100% - 20px)',
            marginLeft: 'auto',
            marginRight: 'auto'
        }),
        []
    );
    const updateImageState = useCallback(() => {
        if (!obj.mainImage) {
            setImageWidth(120);
            setImgLoading(false);
            return;
        }

        const mediumFormatUrl = obj.mainImage.attributes.formats?.medium?.url;
        const imageURL = StrapiUtilsService.updateUploadsUrl(
            mediumFormatUrl ?? obj.mainImage.attributes.url
        );

        setImageWidth(obj.width ?? 200);
        setImageUrl(imageURL);
        setImgLoading(true);
    }, [obj]);

    useEffect(() => {
        updateImageState();
    }, [updateImageState]);

    return (
        <Flex
            alignItems="center"
            justifyContent="center"
            borderColor="grey.border"
            borderWidth={!imgLoading ? 1 : 0}
            width="100%"
            height="100%"
            aspectRatio={1.35}
        >
            <Box
                position="absolute"
                left="50%"
                top="50%"
                transform="translate(-50%, -50%)"
                width="100%"
                height="100%"
                px={imgLoading ? 0 : '10px'}
            >
                {imageurl && (
                    <NextImage
                        src={imageurl}
                        alt={obj.title || ''}
                        width={imageWidth}
                        height={0}
                        opacity={imgLoading ? 1 : 0.2}
                        display="flex"
                        position="absolute"
                        left="50%"
                        top="50%"
                        transform="translate(-50%, -50%)"
                        style={{
                            objectFit: 'contain',
                            justifyContent: 'center',
                            ...(!imgLoading ? nonImageProps : imgProps)
                        }}
                    />
                )}
            </Box>
        </Flex>
    );
}

export default ProductImage;
