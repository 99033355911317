import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const MinusIcon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 12 2" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.585938 1C0.585938 0.585786 0.921724 0.25 1.33594 0.25H10.6693C11.0835 0.25 11.4193 0.585786 11.4193 1C11.4193 1.41421 11.0835 1.75 10.6693 1.75H1.33594C0.921724 1.75 0.585938 1.41421 0.585938 1Z"
            />
        </Icon>
    );
};
