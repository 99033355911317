import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const CookieIcon = (props: IconProps) => {
    return (
        <Icon
            viewBox="0 0 32 32"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
            {...props}
        >
            <path d="M10.6641 17.333V17.3463" />
            <path d="M16 22.667V22.6803" />
            <path d="M16 16V16.0133" />
            <path d="M21.3359 18.667V18.6803" />
            <path d="M14.6641 10.667V10.6803" />
            <path d="M17.5286 4.63453L21.0846 6.10653C20.3798 7.03376 19.9979 8.16619 19.9973 9.33085C19.9966 10.4955 20.3772 11.6284 21.081 12.5564C21.7847 13.4844 22.7728 14.1566 23.8945 14.4702C25.0161 14.7838 26.2096 14.7216 27.2926 14.2932L27.3632 14.4692C27.7692 15.4493 27.7692 16.5505 27.3632 17.5305C26.701 18.5794 26.2459 19.4025 25.9979 19.9999C25.7464 20.6079 25.453 21.5928 25.1179 22.9545C24.7116 23.9345 23.9327 24.7129 22.9526 25.1185C21.5552 25.4688 20.5704 25.7625 19.9979 25.9999C19.365 26.2621 18.5419 26.7172 17.5286 27.3652C16.5485 27.7711 15.4473 27.7711 14.4672 27.3652C13.3979 26.695 12.5748 26.2399 11.9979 25.9999C11.3695 25.7403 10.3846 25.447 9.04325 25.1199C8.06332 24.7136 7.28492 23.9347 6.87925 22.9545C6.52636 21.5528 6.23258 20.5679 5.99791 19.9999C5.73302 19.3616 5.27791 18.5385 4.63258 17.5305C4.22664 16.5505 4.22664 15.4493 4.63258 14.4692C5.27169 13.4772 5.7268 12.6541 5.99791 11.9999C6.22636 11.4488 6.51969 10.4639 6.87791 9.0452C7.28419 8.06528 8.06307 7.28687 9.04325 6.8812C10.4184 6.53987 11.4032 6.24609 11.9979 5.99987C12.6086 5.74653 13.4317 5.29142 14.4672 4.63453C15.4473 4.22859 16.5485 4.22859 17.5286 4.63453Z" />
        </Icon>
    );
};
