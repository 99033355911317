import { Icon } from '@chakra-ui/icon';
import { IconProps } from '@chakra-ui/icons';

export const CloseIcon = (props: IconProps) => {
    return (
        <Icon
            viewBox="0 0 24 24"
            fill="none"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...props}
        >
            <path d="M18 6L6 18" />
            <path d="M6 6L18 18" />
        </Icon>
    );
};
