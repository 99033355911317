import { ComponentStyleConfig } from '@chakra-ui/react';

export const FormLabel: ComponentStyleConfig = {
    baseStyle: {
        color: 'grey.500',
        fontWeight: 500
    }
};

export default FormLabel;
