export enum ValidationKey {
    BankAccountNumber = 'invalid-bank-account-number',
    BankNameInvalid = 'invalid-bank-name',
    BankNameTooShort = 'too-short',
    BuildingNo = 'invalid-building-no',
    BuildingNoCannotStartsWithZero = 'building-number-starts-with-zero',
    CompanyNameTooShort = 'company-name-too-short',
    EmailsNotMatch = 'emails-not-match',
    FieldIsRequired = 'field-is-required',
    FieldToShort = 'field-to-short',
    CityNameTooShort = 'city-name-too-short',
    InvalidCityFormat = 'invalid-city-format',
    InvalidInternationalTaxNumber = 'invalid-international-tax-number',
    InvalidFormat = 'invalid-format',
    InvalidMemberNumber = 'invalid-member-number',
    InvalidPhone = 'invalid-phone',
    InvalidStreetName = 'invalid-street-name',
    InvalidStreetFormat = 'invalid-street-format',
    InvalidTaxNumber = 'invalid-tax-number',
    InvalidZipCode = 'invalid-zip-code',
    NotAdult = 'not-adult',
    NotEmpty = 'not-empty',
    OnlyDigits = 'only-digits',
    PasswordTooLong = 'password-too-long',
    PasswordTooShort = 'password-too-short',
    PasswordsDoesNotMatch = 'passwords-does-not-match',
    PleaseEnterValidEmail = 'please-enter-valid-email',
    SponsorNumber = 'international-sponsor.invalid'
}
