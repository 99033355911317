import {
    Box,
    Fade,
    IconButton,
    Popover,
    PopoverArrow,
    PopoverCloseButton,
    PopoverContent,
    PopoverTrigger,
    Text,
    useDisclosure,
    useMediaQuery
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import FocusLock from 'react-focus-lock';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { FormattedMessage, useIntl } from 'react-intl';

import { EnvelopeIcon } from '@/components/Icons';
import ContactFormWidget from '@/components/MessagesWindow/ContactForm';

import breakpoints from '@/../themes/default-theme/breakpoints';

function MessagesWindow() {
    const { locale } = useRouter();
    const intl = useIntl();
    const { onOpen, onClose, isOpen } = useDisclosure();
    const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.lg})`, {
        ssr: true,
        fallback: true
    });
    const firstFieldRef = useRef(null);
    const [openIcon, setOpenIcon] = useState(false);

    useEffect(() => {
        setTimeout(() => setOpenIcon(true), 3000);
    }, []);

    useEffect(() => {
        setOpenIcon(!isOpen);
    }, [isOpen]);

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_PUBLIC_KEY as string}
            language={locale}
            scriptProps={{
                async: false,
                defer: false,
                appendTo: 'head',
                nonce: undefined
            }}
        >
            <Box
                position="fixed"
                bottom={{ base: 4, sm: 6 }}
                right={{ base: 4, sm: 6 }}
                zIndex={999}
            >
                <Popover
                    isOpen={isOpen}
                    initialFocusRef={firstFieldRef}
                    onOpen={onOpen}
                    onClose={onClose}
                    openDelay={0}
                    gutter={-48}
                >
                    <PopoverTrigger>
                        <Fade in={openIcon}>
                            <IconButton
                                size="lg"
                                colorScheme="brand"
                                icon={<EnvelopeIcon color="white" w={7} h={7} />}
                                aria-label={intl.formatMessage({ id: 'message-window.open' })}
                            />
                        </Fade>
                    </PopoverTrigger>
                    <PopoverContent
                        overflow="hidden"
                        border="none"
                        shadow="0 10px 29px rgba(57, 93, 140, 0.13)"
                        right={{ base: 0, sm: 10 }}
                        width={{ base: '100%', md: '370px' }}
                        maxW={{ base: '100vw', md: 'max-content' }}
                    >
                        <FocusLock returnFocus persistentFocus={false}>
                            <PopoverArrow />
                            <PopoverCloseButton
                                right={0}
                                top={0}
                                bgColor="brand.main"
                                color="white"
                                width={10}
                                height={10}
                            />
                            {isDesktop && (
                                <Box
                                    pt={10}
                                    pb={6}
                                    px={5}
                                    borderBottom="1px"
                                    borderColor="grey.border"
                                >
                                    <Text
                                        display="inline-table"
                                        lineHeight={1}
                                        bgColor="accent.100"
                                        py={5}
                                        px={8}
                                    >
                                        <FormattedMessage id="message-window.title" />
                                    </Text>
                                    <Text fontSize="sm" color="grey.600">
                                        <FormattedMessage id="better-style" />
                                    </Text>
                                </Box>
                            )}
                            <Box px={7} py={5}>
                                <ContactFormWidget />
                            </Box>
                        </FocusLock>
                    </PopoverContent>
                </Popover>
            </Box>
        </GoogleReCaptchaProvider>
    );
}

export default MessagesWindow;
