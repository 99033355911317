import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const BracketOpenIcon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 10 38" {...props}>
            <path d="M9.73601 0L10 0.309203C5.48046 4.56341 2.37064 10.273 2.37064 19C2.37064 27.727 5.48574 33.4366 10 37.6908L9.73601 38C4.69377 34.0976 0 27.8549 0 19C0 10.1451 4.69377 3.89703 9.73601 0Z" />
        </Icon>
    );
};
