import axios from 'axios';

import { LocaleProps } from '@/models/props/LocalizationProps';
import {
    BasicProductCategory,
    ProductCategoryPageProps,
    ProductCategoryProps
} from '@/models/props/ProductCategoryProps';
import { EnvironmentService } from '@/services/EnvironmentService';

const CATEGORIES_URL = EnvironmentService.getPublicApiURL() + '/product/categories';
const PRODUCT_CATEGORIES = EnvironmentService.getCmsInternalApiUrl() + '/products-categories';

async function getAllCategories(): Promise<ProductCategoryProps[]> {
    const url = new URL(CATEGORIES_URL);

    const { data } = await axios.get(url.toString());

    return data;
}

export async function getCategoryBySlug(slug: string) {
    const url = new URL(CATEGORIES_URL);

    url.pathname = url.pathname.concat('/', slug);

    return await axios.get<BasicProductCategory[]>(url.toString());
}

export async function getProductCategoryPage(slug: string, locale: LocaleProps) {
    const url = new URL(PRODUCT_CATEGORIES);
    const urlSearchParams = url.searchParams;

    urlSearchParams.set('locale', locale);
    urlSearchParams.set('filters[slug][$eq]', slug);
    urlSearchParams.append('populate', 'image');

    const { data } = await axios.get<ProductCategoryPageProps>(url.toString());

    return data;
}

export const CategoryService = {
    getAllCategories,
    getCategoryBySlug,
    getProductCategoryPage
};
