import { Badge, BadgeProps, forwardRef } from '@chakra-ui/react';
import { useEffect } from 'react';

import { useCurrentUser } from '../../../context/currentUserContext';

export const UnreadNotificationsCounter = forwardRef<BadgeProps, 'div'>((props, ref) => {
    const { unreadNotificationsCounter, updateUnreadNotificationsCounter } = useCurrentUser();

    useEffect(() => {
        updateUnreadNotificationsCounter();
    }, [updateUnreadNotificationsCounter]);

    return (
        <Badge
            ref={ref}
            bgColor="grey.950"
            color="white"
            minW={17}
            h={17}
            display="flex"
            justifyContent="center"
            alignItems="center"
            {...props}
        >
            {unreadNotificationsCounter}
        </Badge>
    );
});
