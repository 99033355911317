import { useShoppingCart } from 'context/shoppingCartContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDebounceValue } from 'usehooks-ts';

import { getAxiosResponseErrorObject } from '@/lib';
import { useToast } from '@/hooks/useToast';

import { PresentQuantitySelector } from '@/components/ShoppingCart/PresentQuantitySelector';

import {
    AddToCartProps,
    ButtonSize,
    ProductIdProps
} from '@/models/props/ShoppingCartContextProps';
interface ProductQuantityHandlerProps extends AddToCartProps {
    promotionCode: string;
    size: ButtonSize;
    fetchingData?: boolean;
    stock?: number;
}

function PresentQuantityHandler({
    promotionCode,
    code,
    quantity,
    maxOrder,
    fetchingData,
    size,
    stock
}: ProductQuantityHandlerProps) {
    const {
        cartItems,
        removeFromCart,
        updateItemQuantity,
        currentBasket,
        updateBasketSummary,
        addPresentItem,
        updatePresentItem,
        deletePresentItem,
        setActiveItem
    } = useShoppingCart();
    const { errorToast, infoToast, successToast, warningToast } = useToast();
    const intl = useIntl();
    const [currQuantity, setCurrQuantity] = useState<number>(quantity);
    const [prevQuantity, setPrevQuantity] = useState<number>(quantity);
    const [update, setUpdate] = useState<boolean>(false);
    const [itemCode, setItemCode] = useState<ProductIdProps | null>(null);
    const [debouncedValue] = useDebounceValue<number>(currQuantity, 500);

    const obj = useMemo(
        () => ({
            promotionCode,
            code,
            updateItemQuantity,
            currentBasket
        }),
        [promotionCode, code, updateItemQuantity, currentBasket]
    );

    const increaseQuantityHandle = (quantity: number) => {
        if (itemCode !== code) {
            setItemCode(code);
            setActiveItem({ code, promotionCode });
        }
        if (maxOrder !== undefined && quantity < maxOrder) {
            setCurrQuantity(quantity);
            setUpdate(true);
        }
    };

    const decreaseQuantityHandle = (quantity: number) => {
        if (itemCode !== code) {
            setItemCode(code);
            setActiveItem({ code, promotionCode });
        }
        setUpdate(true);
        setCurrQuantity(quantity);
    };

    const onChangeInputQuantityHandler = (quantity: number) => {
        setCurrQuantity(quantity);
        setUpdate(true);
    };

    const onMinQuantityExceeded = () => {
        removeFromCart(code as number);
    };

    const onMaxQuantityExceeded = () => {
        if (maxOrder === undefined) {
            return;
        }

        setUpdate(true);
        if (!fetchingData) setCurrQuantity(maxOrder);

        infoToast({
            description: intl.formatMessage(
                {
                    id: 'basket.reached-max-order'
                },
                { maxItems: maxOrder.toString() }
            ),
            duration: 3000
        });
    };

    const displayError = useCallback(
        (e: Error) => {
            const error = getAxiosResponseErrorObject(e);
            const title = intl.formatMessage({
                id: 'add-to-cart.update-product-quantity.fail'
            });

            const description =
                error?.errors?.length > 0
                    ? intl.formatMessage({ id: error.errors?.[0].code }) || error.errors?.[0].code
                    : '';

            setCurrQuantity(() => prevQuantity);

            errorToast({
                title,
                description
            });
        },
        [intl, prevQuantity, errorToast]
    );

    useEffect(() => {
        let inBasket = false;

        if (update) {
            const getPresentItem = currentBasket?.gifts.find((item) => item.productCode === code);
            if (!getPresentItem) return;

            if (prevQuantity > 0) inBasket = true;

            const addPresent = async () => {
                try {
                    const data = await addPresentItem(obj.promotionCode, obj.code, debouncedValue);
                    const { gift } = data;
                    const { productDetails } = gift;

                    inBasket = true;

                    setPrevQuantity(debouncedValue);
                    setCurrQuantity(debouncedValue);

                    if (!productDetails || productDetails.quantity > 0) {
                        successToast({
                            title: intl.formatMessage({ id: 'checkout.present.added' })
                        });
                    } else {
                        infoToast({
                            description: intl.formatMessage(
                                { id: 'basket.reached-max-order' },
                                { maxItems: String(gift.quantity - 1) }
                            )
                        });
                    }

                    if (data.summary) updateBasketSummary(data.summary);
                } catch (e) {
                    if (e instanceof Error) {
                        displayError(e);
                    }
                } finally {
                    setItemCode(null);
                    setActiveItem(null);
                }
            };

            const updatePresent = async () => {
                try {
                    const data = await updatePresentItem(
                        obj.promotionCode,
                        obj.code,
                        debouncedValue
                    );
                    const { gift } = data;
                    const { productDetails } = gift;

                    inBasket = true;

                    setPrevQuantity(debouncedValue);

                    if (!productDetails || productDetails.quantity > 0) {
                        successToast({
                            title: intl.formatMessage({ id: 'checkout.present.updated' })
                        });
                    } else {
                        infoToast({
                            description: intl.formatMessage(
                                { id: 'basket.reached-max-order' },
                                { maxItems: String(gift.quantity - 1) }
                            )
                        });
                    }

                    if (data.summary) updateBasketSummary(data.summary);
                } catch (e) {
                    if (e instanceof Error) {
                        displayError(e);
                    }
                } finally {
                    setItemCode(null);
                    setActiveItem(null);
                }
            };

            const deletePresent = async () => {
                try {
                    const data = await deletePresentItem(obj.promotionCode, obj.code);

                    inBasket = false;
                    warningToast({
                        title: intl.formatMessage({ id: 'checkout.present.deleted' })
                    });

                    if (data.summary) updateBasketSummary(data.summary);
                } catch (e) {
                    if (e instanceof Error) {
                        displayError(e);
                    }
                } finally {
                    setItemCode(null);
                    setActiveItem(null);
                }
            };

            if (!inBasket) addPresent();

            if (inBasket && debouncedValue > 0) updatePresent();

            if (debouncedValue <= 0) deletePresent();

            setUpdate(false);
        }
        // eslint-disable-next-line
    }, [debouncedValue]);

    useEffect(() => {
        if (fetchingData) {
            const product = cartItems.find((item) => item.code === obj.code);

            if (product) setCurrQuantity(product.quantity);
        }
    }, [fetchingData, cartItems, setCurrQuantity, obj]);

    return (
        <PresentQuantitySelector
            maxQuantity={maxOrder}
            minQuantity={0}
            quantity={quantity}
            code={obj.code}
            promotionCode={obj.promotionCode}
            stock={stock}
            onDecrease={decreaseQuantityHandle}
            onIncrease={increaseQuantityHandle}
            onMaxQuantityExceeded={() => onMaxQuantityExceeded()}
            onMinQuantityExceeded={() => onMinQuantityExceeded()}
            onInputChange={onChangeInputQuantityHandler}
            size={size}
        />
    );
}

export default PresentQuantityHandler;
