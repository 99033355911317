import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const BasketIcon = (props: IconProps) => {
    return (
        <Icon
            viewBox="0 0 32 33"
            fill="none"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...props}
        >
            <path d="M8.44042 11.167H23.5591C23.9436 11.1669 24.3235 11.25 24.6729 11.4106C25.0223 11.5711 25.3328 11.8053 25.5832 12.0971C25.8335 12.3889 26.0179 12.7314 26.1235 13.1011C26.2291 13.4708 26.2535 13.859 26.1951 14.239L24.5218 25.1083C24.3765 26.0529 23.8978 26.9143 23.1724 27.5365C22.447 28.1587 21.5228 28.5006 20.5671 28.5003H11.4311C10.4756 28.5003 9.55173 28.1582 8.8266 27.5361C8.10146 26.9139 7.62299 26.0527 7.47776 25.1083L5.80442 14.239C5.74598 13.859 5.77041 13.4708 5.87603 13.1011C5.98165 12.7314 6.16597 12.3889 6.41635 12.0971C6.66673 11.8053 6.97725 11.5711 7.32661 11.4106C7.67598 11.25 8.05593 11.1669 8.44042 11.167Z" />
            <path d="M12 15.1667V8.5C12 7.43913 12.4214 6.42172 13.1716 5.67157C13.9217 4.92143 14.9391 4.5 16 4.5C17.0609 4.5 18.0783 4.92143 18.8284 5.67157C19.5786 6.42172 20 7.43913 20 8.5V15.1667" />
        </Icon>
    );
};
