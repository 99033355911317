import { forwardRef, Heading, HeadingProps } from '@chakra-ui/react';

export const H2 = forwardRef<HeadingProps, 'h2'>((props, ref) => {
    return (
        <Heading
            as="h2"
            size={props.size ? props.size : 'h2'}
            fontWeight="medium"
            ref={ref}
            {...props}
        />
    );
});
