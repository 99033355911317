import { AlertIconProps, AlertStatus, useAlertStyles } from '@chakra-ui/alert';
import { WarningIcon } from '@chakra-ui/icons';
import { Box, Spinner } from '@chakra-ui/react';
import { cx } from '@chakra-ui/utils';

import { InfoIcon } from '@/components/Icons';
import { TickIcon } from '@/components/Icons/TickIcon';
import { useAlert } from '@/components/UI/Alert/AlertContext';

const STATUSES = {
    info: { icon: InfoIcon, colorScheme: 'blue' },
    warning: { icon: WarningIcon, colorScheme: 'orange' },
    success: { icon: TickIcon, colorScheme: 'green' },
    error: { icon: WarningIcon, colorScheme: 'red' },
    loading: { icon: Spinner, colorScheme: 'blue' }
};

export function getStatusIcon(status: AlertStatus) {
    return STATUSES[status].icon;
}

export const AlertIcon = ({ ...props }: AlertIconProps) => {
    const { status } = useAlert();
    const BaseIcon = getStatusIcon(status);
    const styles = useAlertStyles();
    const css = status === 'loading' ? styles.spinner : styles.icon;

    return (
        <Box
            as="span"
            display="inherit"
            data-status={status}
            {...props}
            __css={css}
            className={cx('chakra-alert__icon', props.className)}
        >
            {props.children || <BaseIcon h="100%" w="100%" />}
        </Box>
    );
};
