import { Accordion } from './Accordion';
import { Alert } from './Alert';
import { Badge } from './Badge';
import { Box } from './Box';
import { Button } from './Button';
import { Card } from './Card';
import { Checkbox } from './Checkbox';
import { Container } from './Container';
import { Drawer } from './Drawer';
import { Form } from './form';
import { FormLabel } from './form';
import { Heading } from './Heading';
import { Input } from './Input';
import { Link } from './Link';
import { List } from './List';
import { Modal } from './Modal';
import { Popover } from './Popover';
import { Progress } from './Progress';
import { Radio } from './Radio';
import { Select } from './Select';
import { Skeleton } from './Skeleton';
import { Switch } from './Switch';
import { Table } from './Table';
import { Tabs } from './Tabs';
import { Text } from './Text';
import { Textarea } from './Textarea';

const components = {
    Accordion,
    Alert,
    Badge,
    Box,
    Button,
    Card,
    Container,
    Drawer,
    Form,
    FormLabel,
    Heading,
    Input,
    Link,
    List,
    Modal,
    Popover,
    Progress,
    Radio,
    Select,
    Switch,
    Tabs,
    Table,
    Text,
    Skeleton,
    Checkbox,
    Textarea
};

export default components;
