import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

type AdditionalProps = {
    fill?: string;
};

export const UpIcon = (props: IconProps & AdditionalProps) => {
    return (
        <Icon viewBox="0 0 18 10" {...props}>
            <path
                d="M8.43925 0.23617L0.213915 8.72219C0.076602 8.86378 1.58268e-06 9.0519 1.61689e-06 9.24752C1.65109e-06 9.44314 0.0766021 9.63126 0.213915 9.77285L0.22321 9.78199C0.289776 9.85087 0.369902 9.90571 0.458712 9.94319C0.547523 9.98067 0.643161 10 0.73981 10C0.836459 10 0.932098 9.98067 1.02091 9.94319C1.10972 9.90571 1.18984 9.85087 1.25641 9.78199L9.00155 1.79085L16.7436 9.78198C16.8102 9.85086 16.8903 9.90571 16.9791 9.94319C17.0679 9.98067 17.1635 10 17.2602 10C17.3568 10 17.4525 9.98067 17.5413 9.94319C17.6301 9.90571 17.7102 9.85086 17.7768 9.78198L17.7861 9.77285C17.9234 9.63125 18 9.44314 18 9.24752C18 9.05189 17.9234 8.86378 17.7861 8.72218L9.56075 0.23617C9.48841 0.161539 9.40141 0.102124 9.30502 0.0615279C9.20863 0.0209315 9.10486 -1.59194e-06 9 -1.57361e-06C8.89514 -1.55527e-06 8.79136 0.0209316 8.69497 0.061528C8.59858 0.102124 8.51159 0.161539 8.43925 0.23617Z"
                strokeLinecap="round"
                strokeWidth="1"
                fill={props.fill || '#000000'}
            />
        </Icon>
    );
};
