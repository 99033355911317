import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const PlusIcon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 12 12" {...props}>
            <path d="M6.75 1.33337C6.75 0.91916 6.41421 0.583374 6 0.583374C5.58579 0.583374 5.25 0.91916 5.25 1.33337V5.25H1.33594C0.921724 5.25 0.585938 5.58579 0.585938 6C0.585938 6.41421 0.921724 6.75 1.33594 6.75H5.25V10.6667C5.25 11.0809 5.58579 11.4167 6 11.4167C6.41421 11.4167 6.75 11.0809 6.75 10.6667V6.75H10.6693C11.0835 6.75 11.4193 6.41421 11.4193 6C11.4193 5.58579 11.0835 5.25 10.6693 5.25H6.75V1.33337Z" />
        </Icon>
    );
};
