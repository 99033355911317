import { Box, Flex, GridItem, SimpleGrid, Text } from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { H3 } from '@/components/Heading';
import ProductImage from '@/components/Image/ProductImage';
import { ProductItemActions } from '@/components/ShoppingCart/ProductItemActions';
import RemoveItem from '@/components/ShoppingCart/RemoveItem';
import NavLink from '@/components/UI/Links/NavLink';

import { ProductProps } from '@/models/props/ProductCardProps';
import { ProductUtil } from '@/utils';

export function ProductItem({ product, basketItem }: ProductProps) {
    const { code, netVolume, points, priceGrossSingle, priceGross, quantity } = basketItem;
    const intl = useIntl();
    const { cartItems, editable } = useShoppingCart();
    const [currQuantity, setCurrQuantity] = useState<number>(quantity);
    const productUrl = ProductUtil.getProductUrl(product);

    const obj = useMemo(() => ({ code, quantity, product, intl }), [code, quantity, product, intl]);

    useEffect(() => {
        const inBasket = cartItems.find((i) => i.code === obj.code);

        if (inBasket) setCurrQuantity(obj.quantity);
    }, [cartItems, product, obj]);

    return (
        <>
            {product && (
                <SimpleGrid columns={16} gap={4}>
                    <GridItem position="relative" colSpan={5}>
                        <Flex
                            justifyContent="center"
                            alignItems="flex-start"
                            position="relative"
                            h="100%"
                            w="100%"
                        >
                            <ProductImage mainImage={product.mainImage} title={product.title} />
                        </Flex>
                    </GridItem>
                    <GridItem colSpan={11}>
                        <Flex justifyContent="space-between" fontSize="sm" columnGap={4}>
                            <NavLink href={productUrl} pt={1}>
                                <H3 size="h4" fontWeight="medium" mb={0}>
                                    {obj.product.title}
                                </H3>
                            </NavLink>
                            <RemoveItem code={product.code} isDisabled={!editable} />
                        </Flex>
                        <Text fontSize="small">
                            <FormattedMessage id="product-code" />: {product.code}
                        </Text>
                        <Box pt={3}>
                            {currQuantity !== undefined && (
                                <ProductItemActions
                                    quantity={currQuantity}
                                    code={code}
                                    maximalProductCountInOrder={30}
                                    netPrice={String(netVolume)}
                                    priceGross={priceGross}
                                    priceGrossSingle={priceGrossSingle}
                                    currency={product.currency}
                                    points={points}
                                    grossPriceBeforeDiscount={product.grossPriceBeforeDiscount}
                                    discount={product.discount}
                                />
                            )}
                        </Box>
                    </GridItem>
                </SimpleGrid>
            )}
        </>
    );
}
