import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

type AdditionalProps = {
    fill?: string;
};

export const DownIcon = (props: IconProps & AdditionalProps) => {
    return (
        <Icon viewBox="0 0 18 10" {...props}>
            <path
                d="M9.56075 9.76383L17.7861 1.27781C17.9234 1.13622 18 0.948103 18 0.752481C18 0.556858 17.9234 0.368743 17.7861 0.22715L17.7768 0.218013C17.7102 0.149133 17.6301 0.094286 17.5413 0.0568066C17.4525 0.0193272 17.3568 0 17.2602 0C17.1635 0 17.0679 0.0193272 16.9791 0.0568066C16.8903 0.094286 16.8102 0.149133 16.7436 0.218013L8.99845 8.20915L1.25641 0.218013C1.18984 0.149133 1.10972 0.094286 1.02091 0.0568066C0.932096 0.0193272 0.836458 0 0.739811 0C0.643162 0 0.547523 0.0193272 0.458714 0.0568066C0.369902 0.094286 0.289776 0.149133 0.223209 0.218013L0.213915 0.22715C0.076601 0.368743 0 0.556858 0 0.752481C0 0.948103 0.076601 1.13622 0.213915 1.27781L8.43925 9.76383C8.51159 9.83846 8.59859 9.89787 8.69498 9.93847C8.79137 9.97907 8.89514 10 9 10C9.10487 10 9.20864 9.97907 9.30503 9.93847C9.40141 9.89787 9.48841 9.83846 9.56075 9.76383Z"
                strokeLinecap="round"
                strokeWidth="1"
                fill={props.fill || '#000000'}
            />
        </Icon>
    );
};

export default DownIcon;
