import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const OVIIcon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 35.34 12.57" {...props}>
            <path d="M32.64,12.41c-.83,0-1.65,0-2.48,0-.07,0-.17.04-.18-.08-.01-.12.09-.11.16-.12,1.39-.18,1.54-.94,1.55-1.9.03-2.69.01-5.38,0-8.07,0-.21-.03-.42-.07-.63-.12-.68-.53-1.08-1.21-1.2-.12-.02-.24-.05-.36-.06-.07-.01-.09-.05-.08-.11,0-.05.04-.07.09-.08.07,0,.13,0,.2,0,1.6,0,3.19,0,4.79,0,.06,0,.11,0,.17,0,.06,0,.11.01.12.08,0,.07-.02.1-.09.11-.02,0-.04,0-.07,0-1.34.2-1.54.88-1.55,2.01-.02,2.61,0,5.22,0,7.83,0,.19.02.38.04.57.1.84.51,1.28,1.34,1.43.05,0,.11.01.17.02.08,0,.17.01.16.12-.01.12-.12.08-.18.08-.64,0-1.27,0-1.91,0-.2,0-.4,0-.6,0Z" />
            <path d="M17.72.15c.87,0,1.74,0,2.61,0,.09,0,.26-.08.26.1,0,.16-.16.08-.24.09-1.19.15-1.54.7-1.11,1.86.78,2.12,1.6,4.22,2.4,6.33.08.22.13.45.25.65.4.68.34,1.33-.03,2-.19.35-.3.74-.46,1.11-.04.09-.03.24-.18.25-.16,0-.15-.15-.19-.24-.59-1.5-1.18-3-1.77-4.5-.74-1.86-1.47-3.72-2.22-5.58-.21-.53-.52-1.01-.96-1.39-.31-.26-.67-.4-1.05-.48-.07-.02-.21.03-.19-.11.01-.14.13-.09.21-.09.89,0,1.79,0,2.68,0Z" />
            <path d="M13.23,6.36c.03,3.29-2.38,5.58-5.23,6.1-.14.03-.29.06-.43.06-.08,0-.22.1-.24-.07-.02-.13.11-.11.19-.13.01,0,.02,0,.03,0,1.54-.33,2.58-1.27,3.07-2.73.78-2.32.7-4.63-.32-6.87-.66-1.44-1.82-2.27-3.4-2.5-.08-.01-.2.03-.19-.12,0-.13.11-.09.17-.09,2.37.11,4.34.95,5.55,3.12.56,1,.78,2.1.79,3.24Z" />
            <path d="M0,6.75C-.12,2.63,2.79.33,5.66.05c.08,0,.19-.08.22.06.03.13-.1.13-.17.15-2.04.43-3.02,1.83-3.41,3.75-.38,1.9-.28,3.77.48,5.57.66,1.56,1.8,2.54,3.52,2.77.08.01.2-.01.21.12,0,.12-.09.11-.17.1-1.61-.07-3.1-.47-4.34-1.57C.63,9.77.06,8.19,0,6.75Z" />
            <path d="M25.71.15c.75,0,1.49,0,2.24,0,.07,0,.17-.04.2.07.03.12-.07.12-.14.14-1.68.31-2.56,1.49-3.19,2.94-.05.11-.09.23-.15.33-.03.05-.03.17-.15.12-.11-.04-.06-.12-.04-.2.08-.41.17-.83.24-1.24.04-.24.07-.49.09-.73.03-.51-.25-.92-.75-1.1-.16-.06-.33-.07-.48-.13-.1-.04-.3.08-.27-.13.02-.16.21-.07.31-.08.7,0,1.41,0,2.11,0Z" />
        </Icon>
    );
};
