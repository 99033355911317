import { Icon } from '@chakra-ui/icon';
import { IconProps } from '@chakra-ui/icons';

export const InfoIcon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 20 20" {...props}>
            <path d="M9 6.75C9 6.33579 9.33579 6 9.75 6H9.76C10.1742 6 10.51 6.33579 10.51 6.75C10.51 7.16421 10.1742 7.5 9.76 7.5H9.75C9.33579 7.5 9 7.16421 9 6.75Z" />
            <path d="M2.75 1.5C2.41848 1.5 2.10054 1.6317 1.86612 1.86612C1.6317 2.10054 1.5 2.41848 1.5 2.75V16.75C1.5 17.0815 1.6317 17.3995 1.86612 17.6339C2.10054 17.8683 2.41848 18 2.75 18H16.75C17.0815 18 17.3995 17.8683 17.6339 17.6339C17.8683 17.3995 18 17.0815 18 16.75V2.75C18 2.41848 17.8683 2.10054 17.6339 1.86612C17.3995 1.6317 17.0815 1.5 16.75 1.5H2.75ZM0.805456 0.805456C1.32118 0.289731 2.02065 0 2.75 0H16.75C17.4793 0 18.1788 0.289731 18.6945 0.805456C19.2103 1.32118 19.5 2.02065 19.5 2.75V16.75C19.5 17.4793 19.2103 18.1788 18.6945 18.6945C18.1788 19.2103 17.4793 19.5 16.75 19.5H2.75C2.02065 19.5 1.32118 19.2103 0.805456 18.6945C0.289731 18.1788 0 17.4793 0 16.75V2.75C0 2.02065 0.289731 1.32118 0.805456 0.805456Z" />
            <path d="M8 9.75C8 9.33579 8.33579 9 8.75 9H9.75C10.1642 9 10.5 9.33579 10.5 9.75V13H10.75C11.1642 13 11.5 13.3358 11.5 13.75C11.5 14.1642 11.1642 14.5 10.75 14.5H9.75C9.33579 14.5 9 14.1642 9 13.75V10.5H8.75C8.33579 10.5 8 10.1642 8 9.75Z" />
        </Icon>
    );
};
