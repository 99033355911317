import { useShoppingCart } from 'context/shoppingCartContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDebounceValue } from 'usehooks-ts';

import { getAxiosResponseErrorObject } from '@/lib';
import { useToast } from '@/hooks/useToast';

import { ProductQuantitySelector } from '@/components/ShoppingCart/ProductQuantitySelector';

import { ProductActionsDisabled } from '@/models/props/ProductCardProps';
import {
    AddToCartProps,
    ButtonSize,
    ProductIdProps
} from '@/models/props/ShoppingCartContextProps';
interface ProductQuantityHandlerProps extends AddToCartProps {
    size: ButtonSize;
    fetchingData?: boolean;
    onProductQuantityChange?: (item: ProductActionsDisabled | null) => void;
}

function ProductQuantityHandler({
    code,
    quantity,
    maxOrder,
    fetchingData,
    size
}: ProductQuantityHandlerProps) {
    const { removeFromCart, updateItemQuantity, setActiveItem } = useShoppingCart();
    const { errorToast, infoToast } = useToast();
    const intl = useIntl();
    const [currQuantity, setCurrQuantity] = useState<number | string>(quantity);
    const [prevQuantity, setPrevQuantity] = useState<number | string>(quantity);
    const [update, setUpdate] = useState<boolean>(false);
    const [debounceQuantity] = useDebounceValue(currQuantity, 500);
    const [itemCode, setItemCode] = useState<ProductIdProps | null>(null);

    const obj = useMemo(
        () => ({
            code,
            updateItemQuantity
        }),
        [code, updateItemQuantity]
    );

    const increaseQuantityHandle = (quantity: number) => {
        if (itemCode !== code) {
            setItemCode(code);
            setActiveItem({ code });
        }
        if (maxOrder !== undefined && quantity <= maxOrder) {
            setCurrQuantity(quantity);
            setUpdate(true);
        }
    };

    const decreaseQuantityHandle = (quantity: number | string) => {
        if (itemCode !== code) {
            setItemCode(code);
            setActiveItem({ code });
        }
        if (+quantity >= 0) {
            setCurrQuantity(quantity);
            setUpdate(true);
        }
    };

    const onChangeInputQuantityHandler = useCallback((quantity: number | string) => {
        setCurrQuantity(() => quantity);

        if (quantity === '') {
            return;
        }
        setUpdate(true);
    }, []);

    const onMinQuantityExceeded = () => {
        removeFromCart(code as number);
    };

    const onMaxQuantityExceeded = () => {
        if (maxOrder === undefined) {
            return;
        }

        setUpdate(true);

        if (!fetchingData) setCurrQuantity(maxOrder);

        infoToast({
            description: intl.formatMessage(
                {
                    id: 'basket.reached-max-order'
                },
                { maxItems: maxOrder.toString() }
            ),
            duration: 3000
        });
    };

    useEffect(() => {
        if (debounceQuantity === '' || isNaN(+debounceQuantity)) {
            return;
        }

        if (update) {
            const updateItem = async () => {
                await updateItemQuantity(obj.code, debounceQuantity)
                    .then(() => {
                        setPrevQuantity(() => debounceQuantity);
                        setCurrQuantity(debounceQuantity);
                    })
                    .catch((e) => {
                        const error = getAxiosResponseErrorObject(e);
                        const title = intl.formatMessage({
                            id: 'add-to-cart.update-product-quantity.fail'
                        });

                        const description =
                            error?.errors?.length > 0
                                ? intl.formatMessage({ id: error.errors?.[0]?.code })
                                : '';

                        setCurrQuantity(() => prevQuantity);

                        errorToast({
                            title,
                            description
                        });
                    })
                    .finally(() => {
                        setActiveItem(null);
                        setItemCode(null);
                    });
                setUpdate(false);
            };

            updateItem();
        }
        // eslint-disable-next-line
    }, [debounceQuantity, obj.code]);

    useEffect(() => {
        setCurrQuantity(() => quantity);
    }, [quantity, setCurrQuantity]);

    return (
        <ProductQuantitySelector
            disabled={fetchingData}
            maxQuantity={maxOrder}
            minQuantity={0}
            quantity={currQuantity}
            onDecrease={decreaseQuantityHandle}
            onIncrease={increaseQuantityHandle}
            onMaxQuantityExceeded={() => onMaxQuantityExceeded()}
            onMinQuantityExceeded={() => onMinQuantityExceeded()}
            onInputChange={onChangeInputQuantityHandler}
            size={size}
        />
    );
}

export default ProductQuantityHandler;
