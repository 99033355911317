import RoutePath from '../constants/route-path';

export const unLoggedUserShoppingMenu = [
    {
        href: RoutePath.Register,
        translationKey: 'page.join-us.title'
    },
    {
        href: RoutePath.Products,
        translationKey: 'page.shop.title'
    },
    {
        href: RoutePath.Catalog,
        translationKey: 'page.catalog.title'
    },
    {
        href: RoutePath.SuperOffers,
        translationKey: 'super-offers'
    },
    {
        href: '',
        translationKey: 'page.world-ovi.title',
        submenu: [
            {
                href: RoutePath.AboutUs,
                translationKey: 'about-us',
                skipRouter: true
            },
            {
                href: RoutePath.ScientificCouncil,
                translationKey: 'page.scientific-council.title',
                skipRouter: true
            },
            {
                href: RoutePath.Certificates,
                translationKey: 'page.certificates.title',
                skipRouter: true
            },
            {
                href: RoutePath.EggMembrane,
                translationKey: 'page.egg-membrane.title',
                skipRouter: true
            }
        ]
    },
    {
        href: RoutePath.Blog,
        translationKey: 'page.blog.title',
        skipRouter: true
    },
    {
        href: RoutePath.Events,
        translationKey: 'page.events.title'
    },
    {
        href: RoutePath.SignIn,
        translationKey: 'log-in'
    }
];

export const loggedUserShoppingMenu = [
    {
        href: RoutePath.Products,
        translationKey: 'page.shop.title'
    },
    {
        href: RoutePath.Catalog,
        translationKey: 'page.catalog.title'
    },
    {
        href: RoutePath.SuperOffers,
        translationKey: 'super-offers'
    },
    {
        href: RoutePath.MonthActions,
        translationKey: 'actions-of-the-month'
    },
    {
        href: RoutePath.Blog,
        translationKey: 'page.blog.title',
        skipRouter: true
    },
    {
        href: RoutePath.Events,
        translationKey: 'page.events.title'
    },
    {
        href: RoutePath.KnowledgeBase,
        translationKey: 'page.knowledge-base.title'
    },
    {
        href: RoutePath.MyCareer,
        translationKey: 'page.my-career.title'
    }
];
