export const primaryTextColor = 'hsla(0, 0%, 0%, 1)';

const brandBaseColor = '#c6aa76';
const borderColor = '#f3f3f2';
const grayScale = {
    main: '#757575',
    border: borderColor,
    100: borderColor,
    200: '#d1d1d1',
    300: '#BABABA',
    400: '#888888',
    500: '#757575',
    600: '#5d5d5d',
    700: '#4f4f4f',
    800: '#454545',
    900: '#3d3d3d',
    950: '#302923'
};

const colors = {
    brand: {
        main: brandBaseColor,
        darker: '#A28143',
        darkest: '#6C562D',
        500: brandBaseColor,
        600: '#A28143',
        800: '#6C562D'
    },
    accent: {
        main: '#e6e2de',
        border: '#FBF6F2',
        50: '#fcfcfb',
        100: '#faf9f7',
        200: '#FBF6F2',
        300: '#f5f4f2',
        400: '#edebe8',
        500: '#e6e2de',
        600: '#C9C5C0',
        700: '#ab917d',
        800: '#8a6750',
        900: '#66422d',
        950: '#422213'
    },
    danger: {
        50: '#fcf7f0',
        100: '#faefe1',
        200: '#f5d7b8',
        300: '#edbb8e',
        400: '#de7943',
        500: '#d13100',
        600: '#bd2900',
        700: '#9c1f00',
        800: '#7d1700',
        900: '#5e1000',
        950: '#3d0900'
    },
    yellow: {
        main: '#FEEEC6',
        100: '#FEEEC6',
        400: '#ECC94B',
        700: '#e4a802'
    },
    green: {
        50: '#ecf7f1',
        100: '#F3F6F5',
        200: '#DCE5E0',
        300: '#BECFC6',
        400: '#A7BEB2',
        500: '#89A998',
        600: '#6C937E',
        700: '#587766',
        800: '#41584B',
        900: '#2B3B32',
        950: '#151e19'
    },
    gray: grayScale,
    grey: grayScale,
    orange: {
        main: '#FFC355',
        dark: '#B64E05'
    },
    user: {
        main: '#faf9f7',
        none: '#ffffff',
        lame: '#E7E7E7',
        leader: '#FEEEC6',
        transparent: 'transparent'
    },
    myProgress: {
        gold: {
            dark: '#B57F04',
            light: '#E6B952'
        },
        grey: {
            light: '#E6E2DE',
            dark: '#C9C5C0'
        }
    }
};

export const focusColor = colors.brand['main'];

export default colors;
