import { Box, Button, ButtonProps, Text, VisuallyHidden } from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { FormattedMessage } from 'react-intl';

import BasketTime from '@/components/BasketTime';
import { BasketIcon } from '@/components/Icons';
import CartQuantity from '@/components/ShoppingCart/CartQuantity';

function ButtonCart(props: ButtonProps) {
    const { ...rest } = props;
    const { cartQuantity, basketLocked } = useShoppingCart();

    return (
        <Button
            variant="ghost"
            display="flex"
            flexDirection="column"
            alignItems="center"
            p={2}
            opacity={basketLocked === false ? 1 : 0.3}
            cursor={basketLocked === false ? 'pointer' : 'not-allowed'}
            {...rest}
        >
            <Box position="relative">
                <VisuallyHidden>
                    <FormattedMessage id="basket" />
                </VisuallyHidden>
                {cartQuantity > 0 ? (
                    <>
                        <CartQuantity quantity={cartQuantity} />
                        <Text
                            fontSize=".75rem"
                            color="black"
                            pointerEvents="none"
                            position="absolute"
                            bottom="-0.75rem"
                            fontWeight="400"
                            left="50%"
                            transform="translateX(-50%)"
                        >
                            <BasketTime />
                        </Text>
                    </>
                ) : (
                    <BasketIcon
                        height={{ base: 8, xl: 7, '3xl': 8 }}
                        width={{ base: 8, xl: 7, '3xl': 8 }}
                        pointerEvents="none"
                    />
                )}
            </Box>
        </Button>
    );
}

export default ButtonCart;
