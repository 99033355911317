import { Icon } from '@chakra-ui/icon';
import { IconProps } from '@chakra-ui/icons';

export const ComplaintIcon = (props: IconProps) => {
    return (
        <Icon
            viewBox="0 0 16 17"
            fill="none"
            stroke="#302923"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...props}
        >
            <path d="M8 13.1666H4C3.46957 13.1666 2.96086 12.9559 2.58579 12.5808C2.21071 12.2057 2 11.697 2 11.1666V5.83325C2 5.30282 2.21071 4.79411 2.58579 4.41904C2.96086 4.04397 3.46957 3.83325 4 3.83325H12C12.5304 3.83325 13.0391 4.04397 13.4142 4.41904C13.7893 4.79411 14 5.30282 14 5.83325V8.83325" />
            <path d="M2 7.16675H14" />
            <path d="M4.6665 10.5H4.67317" />
            <path d="M7.3335 10.5H8.66683" />
            <path d="M10.6665 13.1667H14.6665" />
            <path d="M12.6665 11.1667L10.6665 13.1667L12.6665 15.1667" />
        </Icon>
    );
};
