import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Flex, Modal, ModalBody, ModalContent, ModalOverlay, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@/components/UI/Buttons/Button';

import { useApp } from '../../../context/appContext';

export const MessageModal = () => {
    const { isPopupMessageOpen, onPopupMessageClose, hidePopupMessage, popupMessage, popupTitle } =
        useApp();

    return (
        <Modal
            isOpen={isPopupMessageOpen}
            onClose={onPopupMessageClose}
            isCentered
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalBody p={6}>
                    <Flex direction="column" alignItems="center">
                        <InfoOutlineIcon color="brand.main" fontSize={100} />
                        <Box my={4} maxW="full">
                            {popupTitle && (
                                <Text fontSize={28} fontWeight={600} textAlign="center">
                                    {popupTitle}
                                </Text>
                            )}
                            <Box
                                fontSize={20}
                                textAlign="center"
                                whiteSpace="pre-line"
                                dangerouslySetInnerHTML={{
                                    __html: popupMessage.replace(/\\n/g, '<br />')
                                }}
                            />
                        </Box>
                        <Button onClick={() => hidePopupMessage()}>
                            <FormattedMessage id="close" />
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
