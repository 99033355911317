import { IconButton } from '@chakra-ui/react';
import { useMemo } from 'react';

import { MinusIcon, PlusIcon } from '@/components/Icons';

import { ButtonSize } from '@/models/props/ShoppingCartContextProps';

type ProductQuantityButtonProps = {
    onClick: () => void;
    variant: 'up' | 'down';
    size: ButtonSize;
    disabled: boolean;
};

function ProductQuantityButton(props: ProductQuantityButtonProps) {
    const icon =
        props.variant === 'up' ? (
            <PlusIcon pointerEvents="none" width={3} height={3} fill="grey.main" />
        ) : (
            <MinusIcon pointerEvents="none" width={3} height={3} fill="grey.main" />
        );
    const size = useMemo(() => (props.size === 'regular' ? 34 : 5), [props.size]);

    return (
        <IconButton
            onClick={props.onClick}
            bg="transparent"
            p={0}
            h={size}
            w={size}
            minW={size}
            maxH={size}
            icon={icon}
            isDisabled={props.disabled}
            _hover={{ bg: 'grey.100' }}
            _active={{
                bgColor: 'grey.100'
            }}
            aria-label={props.variant}
        />
    );
}

export default ProductQuantityButton;
