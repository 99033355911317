import { ComponentStyleConfig, defineStyle } from '@chakra-ui/react';

export const Skeleton: ComponentStyleConfig = defineStyle({
    defaultProps: {
        startColor: 'accent.border',
        endColor: 'accent.100',
        variant: 'accent',
        borderRadius: 0
    }
});
