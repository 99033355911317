import { IconProps, Link as ChakraLink, LinkProps as ChakraLinkProps } from '@chakra-ui/react';
import NextLink, { LinkProps } from 'next/link';
import { ElementType, PropsWithChildren, useMemo } from 'react';

import { EnvironmentService } from '@/services/EnvironmentService';

export interface NavLinkProps extends LinkProps {
    variant?:
        | 'underlineBrand'
        | 'boldedBrand'
        | 'buttonFade'
        | 'buttonBrand'
        | 'buttonOutline'
        | 'unstyled';
}

const NavLink = (
    props: PropsWithChildren<
        NavLinkProps &
            ChakraLinkProps & {
                leftIcon?: ElementType<IconProps>;
                rightIcon?: ElementType<IconProps>;
                skipRouter?: boolean;
            }
    >
) => {
    const {
        variant = 'unstyled',
        leftIcon: LeftIcon,
        rightIcon: RightIcon,
        skipRouter,
        ...chakraProps
    } = props;

    const href = useMemo(() => skipRouter ? EnvironmentService.getCanonicalURL().concat(props.href) : props.href, [props.href, skipRouter])

    return (
        <ChakraLink
            as={skipRouter || !href ? undefined : NextLink}
            {...chakraProps}
            variant={variant}
            href={href}
            style={
                RightIcon || LeftIcon
                    ? { display: 'flex', alignItems: 'center', gap: '14px' }
                    : undefined
            }
        >
            {LeftIcon && (
                <LeftIcon
                    boxSize={4}
                    pointerEvents="none"
                    transition="transform 200ms ease-in-out"
                    _groupHover={{ transform: 'translateX(0.2rem)' }}
                />
            )}
            {props.children}
            {RightIcon && (
                <RightIcon
                    boxSize={4}
                    pointerEvents="none"
                    transition="transform 200ms ease-in-out"
                    _groupHover={{ transform: 'translateX(0.2rem)' }}
                />
            )}
        </ChakraLink>
    );
};

export default NavLink;
