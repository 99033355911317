import { IconProps } from '@chakra-ui/icons';
import { Icon, useToken } from '@chakra-ui/react';
import { useMemo } from 'react';

type UserProgressLevelIconProps = {
    level?: number;
    lastLevelIndicator?: boolean;
    iconProps?: IconProps;
    userLevel?: number;
};

export const UserLevelStandardIcon = (props: UserProgressLevelIconProps) => {
    const [grey200, grey300, grey400, goldDark, goldLight] = useToken('colors', [
        'grey.200',
        'grey.300',
        'grey.400',
        'myProgress.gold.dark',
        'myProgress.gold.light'
    ]);

    const { iconProps, lastLevelIndicator } = props;
    const level = props.level || 0;
    const userLevel = props.userLevel ?? (props.level as number);

    const levelActive = level <= userLevel;

    const defaultStarColor = 'url(#default-color)';

    const notAchievedLevelColor = grey200;
    const inactiveColorLight = grey300;

    const achievedLevelColor = defaultStarColor;
    const inactiveColorMedium = grey400;

    const paths = useMemo(() => {
        const items = [
            {
                d: 'M 43.222656,15.876756 C 31.44516,16.813046 23.175781,24.253227 19.476562,32.234375 l 3.699219,1.273437 c 3.327962,-6.859575 10.533409,-12.9148 20.046875,-13.742187 z'
            },
            {
                d: 'm 17.855469,36.621093 c -3.165491,11.22929 0.971979,21.764977 7.617114,28.156132 l 2.507922,-2.988942 C 22.606968,56.408279 19.127037,47.22808 21.527344,37.886719 Z'
            },
            {
                d: 'm 61.822125,67.528698 c -11.245571,8.018675 -24.686654,5.957466 -32.740094,0.22097 L 31.589844,64.75 c 6.375851,4.30809 17.197743,6.775666 27.730469,-0.204409 z'
            },
            {
                d: 'm 65.405158,64.52064 c 8.066644,-8.153373 9.817547,-18.832625 7.39669,-27.755656 l -3.691535,1.273535 c 2.060841,7.706031 0.320203,16.77466 -6.214569,23.498157 z'
            },
            {
                d: 'M 71.196191,32.378165 C 66.250744,21.843458 56.54195,16.64803 47.894681,15.921025 l 0.0029,3.892471 c 7.784883,0.796005 15.672385,5.589638 19.601471,13.838335 z'
            }
        ];

        return items.map((item, index) => {
            let fill = level - 1 > index ? achievedLevelColor : notAchievedLevelColor;

            if (!levelActive) {
                fill = level - 1 > index ? inactiveColorMedium : inactiveColorLight;
            }
            return <path key={index} d={item.d} fill={fill} />;
        });
    }, [
        achievedLevelColor,
        inactiveColorLight,
        inactiveColorMedium,
        level,
        levelActive,
        notAchievedLevelColor
    ]);

    return (
        <Icon viewBox="0 0 90.611 105.919" height="auto" {...iconProps}>
            <linearGradient
                id="default-color"
                x1="0.5"
                x2="0.5"
                y2="1"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stopColor="#e6b952" />
                <stop offset="1" stopColor="#a87a28" />
            </linearGradient>
            <g id="g2617">
                <path
                    id="Path_2219"
                    d="M 59.57,54.312001 40.786596,105.91898 31.860182,88.241908 13.29025,95.911128 32.073655,44.304149 Z"
                    fill={levelActive ? goldDark : inactiveColorMedium}
                />
                <path
                    id="Path_2220"
                    d="M 31.037715,54.312194 49.821119,105.91917 58.748473,88.241759 77.318404,95.910979 58.535,44.304 Z"
                    fill={levelActive ? goldLight : inactiveColorLight}
                />
                <circle
                    id="Ellipse_159"
                    cx="45.305"
                    cy="45.305"
                    r="45.305"
                    fill={levelActive ? goldLight : inactiveColorLight}
                />
                <circle id="Ellipse_158" cx="45.305" cy="45.305" r="42.305" fill="#ffffff" />

                <svg x="27" y="31" viewBox="0 0 20 20" width="38" height="38">
                    <path
                        d="M 15.28125 6.039062 L 19.996094 4.019531 L 17.734375 1.800781 L 14.582031 3.035156 Z M 15.28125 6.039062 "
                        fill={levelActive ? '#af8539' : inactiveColorMedium}
                    />
                    <path
                        d="M 11.347656 15.335938 L 19.882812 5.03125 L 15.308594 7.136719 Z M 11.347656 15.335938 "
                        fill={levelActive ? '#af8539' : inactiveColorMedium}
                    />
                    <path
                        d="M 5.417969 3.035156 L 2.265625 1.800781 L 0.00390625 4.019531 L 4.71875 6.039062 Z M 5.417969 3.035156 "
                        fill={levelActive ? '#fdcf5c' : inactiveColorMedium}
                    />
                    <path
                        d="M 0.117188 5.03125 L 8.652344 15.335938 L 4.691406 7.136719 Z M 0.117188 5.03125 "
                        fill={levelActive ? '#fdcf5c' : inactiveColorMedium}
                    />
                    <path
                        d="M 6.53125 2.945312 L 5.769531 6.214844 L 14.472656 6.214844 L 13.6875 2.945312 Z M 6.53125 2.945312 "
                        fill={levelActive ? '#e6b952' : inactiveColorMedium}
                    />
                    <path
                        d="M 5.945312 7.183594 L 10.121094 16 L 14.246094 7.183594 Z M 5.945312 7.183594 "
                        fill={levelActive ? defaultStarColor : inactiveColorMedium}
                    />
                    <path
                        d="M 10.121094 1.980469 L 14.035156 1.980469 L 16.785156 1.066406 L 14.050781 0 L 10.121094 0.105469 L 6.191406 0 L 3.453125 1.066406 L 6.207031 1.980469 Z M 10.121094 1.980469 "
                        fill={levelActive ? '#fdcf5c' : inactiveColorMedium}
                    />
                </svg>
            </g>
            {lastLevelIndicator && (
                <g id="g1529">
                    <path
                        id="Path_2237"
                        data-name="Path 2237"
                        d="m 44.42666,75.30162 a 9.41,9.41 0 0 1 9.592,9.877 c -0.172,5.714 -4.184,9.769 -9.592,9.769 a 9.724,9.724 0 0 1 -9.992,-9.518 10.244,10.244 0 0 1 9.992,-10.128 z"
                        fill="#ffffff"
                    />
                    <circle
                        id="Ellipse_184"
                        data-name="Ellipse 184"
                        cx="-20.738707"
                        cy="-93.788864"
                        r="3.0003555"
                        transform="rotate(164.99109)"
                        fill={levelActive ? goldLight : grey400}
                    />
                </g>
            )}
            {...paths}
        </Icon>
    );
};
