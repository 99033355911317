import { IconProps } from '@chakra-ui/icons';
import { forwardRef, Icon } from '@chakra-ui/react';

export const HeartIcon = forwardRef<IconProps, 'svg'>((props, ref) => {
    return (
        <Icon
            ref={ref}
            viewBox="0 0 32 33"
            fill="none"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...props}
        >
            <path d="M26.0017 17.2629L16.0017 27.1669L6.0017 17.2629C5.34211 16.6211 4.82256 15.8496 4.47577 14.9971C4.12897 14.1446 3.96245 13.2295 3.98669 12.3095C4.01092 11.3895 4.22539 10.4844 4.61658 9.65135C5.00777 8.81829 5.56722 8.07524 6.25969 7.46901C6.95215 6.86278 7.76264 6.40649 8.64012 6.12888C9.51759 5.85127 10.443 5.75835 11.3582 5.85597C12.2733 5.95359 13.1584 6.23965 13.9575 6.69611C14.7567 7.15258 15.4527 7.76957 16.0017 8.50824C16.5531 7.77493 17.2499 7.16333 18.0485 6.71171C18.8471 6.26009 19.7304 5.97817 20.6429 5.8836C21.5555 5.78902 22.4778 5.88383 23.3521 6.16209C24.2263 6.44035 25.0337 6.89607 25.7238 7.50072C26.4138 8.10537 26.9716 8.84595 27.3623 9.67609C27.7529 10.5062 27.968 11.4081 27.9941 12.3252C28.0202 13.2423 27.8567 14.1549 27.5139 15.0059C27.171 15.8569 26.6562 16.628 26.0017 17.2709" />
        </Icon>
    );
});
