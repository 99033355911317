import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const Number5Icon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 183 313" {...props}>
            <path d="M82.2 312.4C64.8667 312.4 50.2 309.6 38.2 304C26.2 298.133 16.8667 291.067 10.2 282.8C3.8 274.267 0.600001 266 0.600001 258C0.600001 251.333 2.46667 245.6 6.2 240.8C9.93334 236 15.1333 233.467 21.8 233.2C27.4 232.933 31.6667 234 34.6 236.4C37.8 238.533 39.9333 241.2 41 244.4C42.3333 247.6 43 250.667 43 253.6C43 256 42.6 258.667 41.8 261.6C41.2667 264.267 40.2 266.933 38.6 269.6C39.9333 274.667 43 279.067 47.8 282.8C52.6 286.267 58.2 288.8 64.6 290.4C71 292 76.8667 292.667 82.2 292.4C94.4667 292.133 104.333 288.933 111.8 282.8C119.267 276.4 124.733 268.4 128.2 258.8C131.933 249.2 133.8 239.067 133.8 228.4C133.8 221.467 133.267 213.733 132.2 205.2C131.133 196.4 128.733 188.133 125 180.4C121.267 172.4 115.533 165.867 107.8 160.8C100.067 155.467 89.6667 152.8 76.6 152.8C72.3333 152.8 67.1333 153.333 61 154.4C54.8667 155.467 48.6 157.067 42.2 159.2C36.0667 161.333 30.4667 164 25.4 167.2L12.2 156L11 26H114.6C127.933 26 137.4 24.1333 143 20.4C148.6 16.6666 151.4 9.86665 151.4 -1.81198e-05H167L164.6 56.8L33.4 59.6V142C39.8 139.333 47.9333 136.933 57.8 134.8C67.6667 132.4 77.8 131.2 88.2 131.2C104.733 131.2 118.867 133.733 130.6 138.8C142.6 143.867 152.333 150.667 159.8 159.2C167.533 167.733 173.133 177.333 176.6 188C180.333 198.667 182.2 209.6 182.2 220.8C182.2 240.8 177.4 257.733 167.8 271.6C158.467 285.2 146.2 295.467 131 302.4C115.8 309.067 99.5333 312.4 82.2 312.4Z" />
        </Icon>
    );
};
