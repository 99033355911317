import { ComponentStyleConfig } from '@chakra-ui/react';

export const Modal: ComponentStyleConfig = {
    baseStyle: {
        dialog: {
            borderRadius: 0
        },
        closeButton: {
            borderRadius: 0
        }
    }
};
