import { Box } from '@chakra-ui/react';
import { useSession } from 'next-auth/react';

import { loggedUserShoppingMenu, unLoggedUserShoppingMenu } from '@/data/menu';

import { CurrentOffer } from '@/components/Navigation/ShoppingNavigation/CurrentOffer';

export const ShoppingNavigationBar = () => {
    const { data } = useSession();
    const shoppingMenu = data?.user ? loggedUserShoppingMenu : unLoggedUserShoppingMenu;

    return (
        <>
            <Box as="nav">
                <CurrentOffer
                    menu={shoppingMenu}
                    display="inline"
                    textAlign="right"
                    flexWrap="nowrap"
                    listStyleType="none"
                    mx={0}
                    alignItems="center"
                    justifyContent="center"
                />
            </Box>
        </>
    );
};
