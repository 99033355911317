import { Box, Flex, Spinner } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useToast } from '@/hooks/useToast';

import { ComplaintIcon, MoneyIcon } from '@/components/Icons';
import { CatalogBox } from '@/components/InfoBar/CatalogBox';
import { MoneyBox } from '@/components/InfoBar/MoneyBox';

import { useShoppingCart } from '@/../context/shoppingCartContext';

import { useCurrentUser } from '../../../context/currentUserContext';

type InfoBarProps = {
    scrolledSm?: boolean;
};

export const InfoBar = ({ scrolledSm }: InfoBarProps) => {
    const intl = useIntl();
    const { currentBasket } = useShoppingCart();
    const { errorToast } = useToast();
    const [loading, setLoading] = useState(true);
    const { currentUser, moneySummary, fetchUser, updateMoneySummary } = useCurrentUser();

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    const fetchMoneySummary = useCallback(async () => {
        try {
            setLoading(() => true);
            await updateMoneySummary();
        } catch {
            errorToast({
                description: intl.formatMessage({ id: 'my-money.fetch-money.description' })
            });
        }
    }, [updateMoneySummary, errorToast, intl]);

    useEffect(() => {
        if (currentUser) {
            updateMoneySummary();
        }
    }, [currentUser, fetchMoneySummary, updateMoneySummary]);

    useEffect(() => {
        if (loading && moneySummary) {
            setLoading(() => false);
        }
    }, [loading, moneySummary]);

    return (
        <Flex bg="accent.50" width="full" maxW="container.3xl" mx="auto">
            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                alignItems="flex-start"
                justifyContent="center"
                columnGap={8}
                px={4}
                py={scrolledSm ? 2 : 4}
                color="grey.500"
                fontSize={14}
                flexGrow={{ base: 1, sm: 0 }}
                position="relative"
                ml={{ base: 0, md: 'auto' }}
            >
                {loading && (
                    <Box
                        position="absolute"
                        inset={0}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        bgColor="rgba(255, 255, 255, .75)"
                    >
                        <Spinner />
                    </Box>
                )}
                {currentUser && !currentUser?.company && (
                    <MoneyBox
                        money={moneySummary?.myMoney}
                        currencySymbol={currentBasket?.summary.currency}
                        label={intl.formatMessage({ id: 'my-money' })}
                    >
                        <MoneyIcon w="18px" h="18px" />
                    </MoneyBox>
                )}
                <MoneyBox
                    money={moneySummary?.refundMoney}
                    currencySymbol={currentBasket?.summary?.currency}
                    label={intl.formatMessage({ id: 'my-money-from-complaints' })}
                >
                    <ComplaintIcon w="18px" h="18px" />
                </MoneyBox>
                <CatalogBox endDate={moneySummary?.catalogEndDate} />
            </Flex>
        </Flex>
    );
};
