import { ComponentStyleConfig } from '@chakra-ui/react';

export const Card: ComponentStyleConfig = {
    variants: {
        outline: {
            container: {
                borderRadius: 0
            }
        },
        frame: {
            container: {
                borderWidth: 1,
                borderRadius: 0,
                borderColor: 'grey.100'
            }
        }
    }
};
