import { Text } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { FieldValues } from 'react-hook-form/dist/types';
import { FormattedMessage } from 'react-intl';

import { ObjectUtil } from '@/utils/ObjectUtil';

import { FormControlErrorProps } from './FormControlErrorProps';

export const FormControlError = (props: PropsWithChildren<FormControlErrorProps<FieldValues>>) => {
    const { control, formState, children, values } = props;
    const controlError = ObjectUtil.getByPath(formState.errors, control.name);
    const message = controlError?.message || controlError?.type || '';

    return (
        <>
            {children}
            {message && (
                <Text flexBasis="100%" color="danger.500" fontSize="xs" marginBottom="2">
                    <FormattedMessage id={message.toString()} values={values} />
                </Text>
            )}
        </>
    );
};
