import { Box, ListItem, UnorderedList } from '@chakra-ui/react';

import { FooterProps } from '@/models/props/NavigationFooterProps';

import NavLink from '../UI/Links/NavLink';

export const FooterNavigation = (props: Pick<FooterProps, 'menu'>) => {
    const navListElements = props?.menu?.map((item, index) => {
        return (
            <ListItem key={index} textAlign="center" p={2}>
                <NavLink
                    href={`/${item.url}`}
                    skipRouter={item.skipRouter}
                    fontSize="1.125rem"
                    fontWeight="medium"
                    lineHeight="1.35"
                >
                    {item.label}
                </NavLink>
            </ListItem>
        );
    });

    return (
        <>
            {!!props?.menu?.length && (
                <Box as="nav">
                    <UnorderedList
                        styleType="none"
                        margin={0}
                        display="flex"
                        flexWrap="wrap"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection={{ base: 'column', lg: 'row' }}
                        columnGap={4}
                        rowGap={9}
                    >
                        {navListElements}
                    </UnorderedList>
                </Box>
            )}
        </>
    );
};
