import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const ArrowDownIcon = (props: IconProps) => {
    return (
        <Icon
            viewBox="0 0 24 24"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...props}
        >
            <path d="M12 5V19" />
            <path d="M16 15L12 19" />
            <path d="M8 15L12 19" />
        </Icon>
    );
};
