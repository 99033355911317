import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const ShieldIcon = (props: IconProps) => {
    return (
        <Icon
            viewBox="0 0 20 20"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
            {...props}
        >
            <path d="M10.001 2.5C11.9475 4.22215 14.4881 5.11881 17.0843 5C17.4623 6.28585 17.578 7.63456 17.4244 8.96598C17.2709 10.2974 16.8513 11.5844 16.1905 12.7504C15.5297 13.9165 14.6413 14.9378 13.578 15.7537C12.5148 16.5696 11.2983 17.1635 10.001 17.5C8.70368 17.1635 7.48723 16.5696 6.42396 15.7537C5.36068 14.9378 4.47226 13.9165 3.8115 12.7504C3.15074 11.5844 2.73111 10.2974 2.57756 8.96598C2.42402 7.63456 2.53967 6.28585 2.91767 5C5.51394 5.11881 8.05448 4.22215 10.001 2.5Z" />
        </Icon>
    );
};
