import { ComponentStyleConfig } from '@chakra-ui/react';

export const Popover: ComponentStyleConfig = {
    baseStyle: {
        content: {
            borderRadius: 0
        },
        closeButton: {
            borderRadius: 0
        }
    }
};
