import { ComponentStyleConfig } from '@chakra-ui/react';

export const Select: ComponentStyleConfig = {
    baseStyle: {
        field: {
            bg: 'white',
            border: '1px solid',
            borderRadius: 0,
            borderColor: 'grey.500',
            _hover: {
                borderColor: 'brand.main'
            }
        }
    },
    variants: {
        outline: {
            field: {
                bg: 'white',
                borderRadius: 0,
                borderColor: 'grey.500',
                _hover: {
                    borderColor: 'brand.main'
                }
            }
        }
    },
    defaultProps: {
        focusBorderColor: 'brand.main',
        errorBorderColor: 'danger.500'
    }
};
